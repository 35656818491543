<template>
<div class="nothing_here">
<!--    <h2>Nothing here yet <br>Go Explore!</h2>-->
    <div class="nothing_here_image"></div>
</div>
</template>

<script>
export default {
    name: "nothingHereYet",
}
</script>

<style lang="scss" scoped>
.nothing_here {
    width: 100%;
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
    position: relative;
    z-index: 1;
    
    .nothing_here_image {
        display: flex;
        width: 100%;
        max-width: 400px;
        height: auto;
        aspect-ratio: 1/1;
        margin: 0 auto;
        background-image: url('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYet.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        
        @media screen and (max-width: 479px) {
            background-image: url('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYetMobile.svg');
            margin-top: 60px;
            margin-bottom: 50px;
            max-width: 90%;
        }
    }

    @media screen and (max-width: 479px) {
        margin-top: 0;
        padding-top: 60px;
    }
}
</style>