// Environment detection utility
export const isNativeApp = () => {
  const result = window.IS_NATIVE_APP === true;
  console.log('[Environment] isNativeApp check:', result);
  return result;
};

export const isFacebookMessenger = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1;
};

// Initialize environment detection
export const initEnvironment = () => {
  console.log('[Environment] Initializing...');
  // Make applyEnvironmentClasses available globally for native app
  window.applyEnvironmentClasses = applyEnvironmentClasses;
  
  // Default to true, then detect if we're in web environment
  if (typeof window.IS_NATIVE_APP === 'undefined') {
    window.IS_NATIVE_APP = true;
    
    // Check if we're in a web environment
    const isWebEnvironment = !window.webkit && !window.capacitor;
    if (isWebEnvironment) {
      window.IS_NATIVE_APP = false;
      console.log('[Environment] Detected web environment, set IS_NATIVE_APP to false');
    } else {
      console.log('[Environment] Detected native environment, keeping IS_NATIVE_APP as true');
    }
  } else {
    console.log('[Environment] IS_NATIVE_APP already set to:', window.IS_NATIVE_APP);
  }
};

// Apply environment-specific classes
export const applyEnvironmentClasses = () => {
  console.log('[Environment] Applying classes...');
  const html = document.documentElement;
  const isNative = isNativeApp();
  const isFBMessenger = isFacebookMessenger();
  console.log('[Environment] Is native app:', isNative);
  console.log('[Environment] Is Facebook Messenger:', isFBMessenger);
  
  if (isNative) {
    html.classList.add('app-mode');
    html.classList.remove('web-mode');
    console.log('[Environment] Added app-mode class');
  } else {
    html.classList.add('web-mode');
    html.classList.remove('app-mode');
    console.log('[Environment] Added web-mode class');
  }

  if (isFBMessenger) {
    html.classList.add('fb-messenger');
    console.log('[Environment] Added fb-messenger class');
  } else {
    html.classList.remove('fb-messenger');
  }
  
  // Log final state
  console.log('[Environment] Final classList:', html.classList.toString());
}; 