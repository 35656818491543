import axios from 'axios';
const initialState = () => ({
    createLinkStripeAccount: null,
    stripeAccount: null,
    stripeAccountLink: null,

    //loaders
    loaderCreateStripeAccount: false,
    loaderGetStripeAccount: false,
    loaderGetStripeAccountLink: false
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_CREATE_LINK_STRIPE_ACCOUNT(state, payload) {
            state.createLinkStripeAccount = payload;
        },
        SET_STRIPE_ACCOUNT(state, payload) {
            state.stripeAccount = payload;
        },
        SET_STRIPE_ACCOUNT_LINK(state, payload) {
            state.stripeAccountLink = payload;
        },

         // loaders
        SET_LOADER_CREATE_STRIPE_ACCOUNT(state, payload) {
            state.loaderCreateStripeAccount = payload;
        },
        SET_LOADER_GET_STRIPE_ACCOUNT(state, payload) {
            state.loaderGetStripeAccount = payload;
        },
        SET_LOADER_GET_STRIPE_ACCOUNT_LINK(state, payload) {
            state.loaderGetStripeAccountLink = payload;
        }
    },
    actions: {
        CREATE_STRIPE_ACCOUNT({commit}, params) {
            commit('SET_LOADER_CREATE_STRIPE_ACCOUNT', true);
            return axios.post(`user/stripe-account`, params)
                .then((response) => {
                    commit('SET_CREATE_LINK_STRIPE_ACCOUNT', response.data.result);
                })
                .catch(err => console.error(`CREATE_STRIPE_ACCOUNT, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_CREATE_STRIPE_ACCOUNT', false);
                });
        },
        GET_STRIPE_ACCOUNT({commit}, params) {
            commit('SET_LOADER_GET_STRIPE_ACCOUNT', true);
            return axios.get(`user/stripe-account`, {params: params})
                .then((response) => {
                   commit('SET_STRIPE_ACCOUNT', response.data.result);
                    // complete, no-account, pending
                   // commit('SET_STRIPE_ACCOUNT', {status: 'pending'});
                })
                .catch(err => {
                    commit('SET_STRIPE_ACCOUNT', {status: 'no-account'});
                    console.error(`GET_STRIPE_ACCOUNT, ${err}`)
                })
                .finally(() => {
                    commit('SET_LOADER_GET_STRIPE_ACCOUNT', false);
                });
        },
        GET_LINK_STRIPE_ACCOUNT({commit}, params) {
            commit('SET_LOADER_GET_STRIPE_ACCOUNT_LINK', true);
            return axios.get(`user/stripe-account/link`, {params: params})
                .then((response) => {
                   commit('SET_STRIPE_ACCOUNT_LINK', response.data.result);
                })
                .catch(err => console.error(`GET_LINK_STRIPE_ACCOUNT, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_GET_STRIPE_ACCOUNT_LINK', false);
                });
        }
    },
    getters: {
        CREATE_LINK_STRIPE_ACCOUNT: state => state.createLinkStripeAccount,
        STRIPE_ACCOUNT: state => state.stripeAccount,
        STRIPE_ACCOUNT_LINK: state => state.stripeAccountLink,

        // loaders
        LOADER_CREATE_STRIPE_ACCOUNT: state => state.loaderCreateStripeAccount,
        LOADER_GET_STRIPE_ACCOUNT: state => state.loaderGetStripeAccount,
        LOADER_GET_STRIPE_ACCOUNT_LINK: state => state.loaderGetStripeAccountLink
    }
}
