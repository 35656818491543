<script>
import withoutFooter from "@/layouts/withoutFooter.vue";
import {mapActions, mapGetters} from "vuex";
import {StripeElementPayment} from '@vue-stripe/vue-stripe';

export default {
	name: 'membershipCheckOut',
	components: {
		withoutFooter,
		StripeElementPayment,
	},
	data() {
		return {
			stripeToken: process.env.VUE_APP_STRIPE_API_TOKEN,
			stripeElementKey: 0,

			stripeOptions: {
				clientSecret: null,
				appearance: {
					variables: {
						fontSizeBase: '16px',
						colorText: '#ffffff',
						colorDanger: '#E94E4E',
						borderRadius: '5px',
						colorBackground: '#0C1324',
						colorIconTabSelected: '#ffffff',
					},
					rules: {
						'.Tab': {
							padding: '12px 12px 7px 12px',
							border: 'none',
							backgroundColor: '#182034',
							boxShadow: 'none'
						},
						'.Tab--selected, .Tab--selected:focus': {
							border: '1px solid #5F6986',
							boxShadow: 'none',
						},
						'.TabLabel': {
							color: '#ffffff',
						},
						'.Label': {
							color: '#dcdcdc',
						},
						'.Input': {
							boxShadow: 'none',
						},
						'.Input--invalid': {
							boxShadow: 'none',
							border: '1px solid #E94E4E'
						},
						'.Input:focus': {
							boxShadow: 'none',
							border: '1px solid #5F6986'
						},

						'.Input::placeholder': {
							color: '#7481A6',
						},
						'.Error': {
							fontSize: '12px',
							marginLeft: '5px'
						}
					}
				},
			},
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.host}/download-purchased`
			},
			redirect: "if_required",
			cancelURL: `${window.location.protocol}//${window.location.host}/check-out`,
			stripeElements: '',
			loaderShoppingCart: false,
			loaderPayment: false,
			redirectStatus: null,
			loading: false,
			///
			clientSecret: '',
			membership: {},
			isSmallScreen: false
		};
	},
	computed: {
		...mapGetters({
			authData: 'AUTH_DATA',
			centrifuge: 'CENTRIFUGE_CLIENT',
		}),
	},
	created() {
		this.getCentrifuge()
			.then(() => {
				const self = this;
				const sub = this.centrifuge.newSubscription(`webhook_purchase_membership_${this.authData.userId}`);
				sub.on('publication', function (ctx) {
					console.log('ctx.data', ctx.data);
					if (ctx.data.status === 'success') {
						console.log('success');
            if (ctx.data.downgrade) {
              self.$router.push({name: 'subscriptions'});
              return;
            }
						self.$router.push({name: 'subscriptionSuccess'});
					}
				}).subscribe();

				this.centrifuge.connect();
			}).catch(err => console.error(`Error: checkOut - getCentrifuge, ${err}`));
	},
	mounted() {
		this.checkScreenSize();
		window.addEventListener('resize', this.checkScreenSize);

		// const { clientSecret, membership } = this.$route.query;
		const {clientSecret, membership} = this.$route.params;
		if (!clientSecret) {
			this.$router.push({name: 'memberships'});
			return;
		}

		this.clientSecret = clientSecret;
		this.membership = JSON.parse(membership);
		this.stripeOptions.clientSecret = this.clientSecret;
	},
	methods: {
		...mapActions({
			getCentrifuge: 'GET_CENTRIFUGE_CLIENT',
		}),
		goToHome() {
			this.$router.push({name: 'explore'});
		},
		remove() {
			this.$router.push({name: 'memberships'});
		},
		// changeStripeClientSecret(clientSecret) {
		// 	if (this.stripeOptions.clientSecret !== clientSecret) {
		// 		this.stripeElementKey += 1;
		// 		this.stripeOptions.clientSecret = clientSecret;
		// 	}
		// },

		submitPaymentCallback() {
			this.loading = false;
		},

		// async createCardForm() {
		// 	this.getPurchaseClientSecret()
		// 		.then(() => {
		// 			if (this.purchaseClientSecret) {
		// 				this.changeStripeClientSecret(this.purchaseClientSecret)
		// 			}
		// 		});
		// },
		async submitPayment() {
			this.$refs.paymentRef.submit();
			this.loading = true;
		},
		checkScreenSize() {
			this.isSmallScreen = window.innerWidth < 681;
			if (this.isSmallScreen) {
				this.stripeOptions.appearance.variables.fontSizeBase = '18px';
				this.stripeOptions.appearance.rules['.Label'].color = '#bababa'

			} else {
				this.stripeOptions.appearance.variables.fontSizeBase = '16px';
			}
		},
		toggleMembership() {
			const membership = document.querySelector('.membership');
			membership.classList.toggle('active');
		}
	},
	beforeDestroy() {
		// Прибрати слухача подій при знищенні компонента
		window.removeEventListener('resize', this.checkScreenSize);
	}
}
</script>

<template>
	<withoutFooter class="without_footer">
		<div class="double_header _membership">
			<div class="container">
				<div class="logo" @click="goToHome"></div>
			</div>
		</div>
		<div class="check-out-page _membership">
			<div class="order_summary" :class="membership.type">
				<div class="half_container left">
					<div class="head">Order Summary</div>

					<div class="wrap_membership">
						<div class="membership active">
							<div class="main_info">
								<div class="left_info">
									<div class="name">
										<template v-if="membership.type !== 'founder'">{{ membership.type }} Membership</template>
										<template v-if="membership.type === 'founder'">
											<template v-if="!isSmallScreen">Founder's Club</template>
											<template v-if="isSmallScreen">Founders<br> Membership</template>
										</template>
									</div> <!-- founder -->
									<div class="description">
										<template v-if="membership.type !== 'founder'">
											All the necessities, plus all the niceties
										</template>
										<template v-if="membership.type === 'founder'">Lifetime premium membership</template>
									</div>
								</div>
								<div class="right_price">
									<button type="button" class="remove" @click="remove">Remove</button>
									<div class="price">${{ membership.price }} <span>USD</span></div>
									<div class="period">
										<template v-if="membership.type !== 'founder'">
											<span>Billed</span>
											<template v-if="membership.period === 'month'">Monthly</template>
											<template v-if="membership.period === 'year'">Yearly</template>
										</template>
										<template v-if="membership.type === 'founder'">Single Purchase</template>
									</div>
								</div>
							</div>
							<div class="description_info special" :class="membership.type">
								<ul v-if="membership.type === 'premium'">
									<li>High Quality Streaming</li>
									<li>50 Free downloads x day</li>
									<li>20 Suggestion votes (soon)</li>
									<li>Bookmarks (soon)</li>
									<li>Ability to comment</li>
									<li>List view</li>
									<li>Premium badge</li>
									<li>No Ads</li>
								</ul>
								<ul v-if="membership.type === 'founder'">
									<li>Pay once, premium forever</li>
									<li>Exclusive founder's badge</li>
									<li>High Quality Streaming</li>
									<li>50 Suggestion votes</li>
									<li>Unlimited bookmarks</li>
									<li>Global Discovery mode</li>
									<li>List view</li>
									<li>No Ads</li>
								</ul>
							</div>

						</div>
					</div>
				</div>

			</div>
			<div class="payment_form">
				<div class="half_container right">
					<div class="wrap_form">
						<div class="head">Payment</div>
						<!--						<div class="sub_head">-->
						<!--							You will be charged $4.99/mo in 14 days unless subscription is canceled-->
						<!--						</div>-->
						<template v-if="!redirectStatus">
							<div class="wrap_total">
								<div class="row_total summary">

									<template v-if="membership.type !== 'founder'">
										<!-- trial -->
										<!--										<div class="label">Due now:</div>-->
										<!--										<div class="value">$ {{membership.price}}</div>-->

										<div class="label">Total:</div>
										<div class="value">${{ membership.price }}<span class="mobile_period">/{{membership.period}}</span></div>
									</template>
									<template v-if="membership.type === 'founder'">
										<div class="label">Total</div>
										<div class="value">${{ membership.price }}</div>
									</template>
								</div>
							</div>

							<template v-if="stripeOptions.clientSecret">
								<stripe-element-payment
									ref="paymentRef"
									:pk="stripeToken"
									:elements-options="stripeOptions"
									:confirm-params="confirmParams"
									:cancel-url="cancelURL"
									:redirect="redirect"
									:key="stripeElementKey"
									@error="submitPaymentCallback"
								/>
								<div class="actions_form">
									<button 
										type="button" 
										@click="submitPayment" 
										class="pay" 
										v-ripple 
										:class="{'loading': loading}"
										:disabled="loading"
									>
										<v-progress-circular :width="2" indeterminate></v-progress-circular>
										<template v-if="membership.type === 'premium' && isSmallScreen">Subscribe</template>
											<template v-else>Pay</template>
									</button>
								</div>
							</template>

						</template>
						<template v-else>
							<div v-if="redirectStatus === 'succeeded'">succeeded</div>
						</template>

					</div>
				</div>
			</div>
		</div>
	</withoutFooter>
</template>


<style scoped lang="scss">
.pay {
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}
</style>
