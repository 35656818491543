<template>
	<div class="p-memberships full_page">
		<v-container>
			<div class="memberships_heading">
				<h2>Memberships</h2>
				<h6>Thank you for your support!</h6>
			</div>

			<div v-if="!LOADER_GET_ALL_SUBSCRIPTIONS && !LOADER_GET_SUBSCRIPTION && !loader" class="bill_switch">
				<button type="button" @click="billSwitch('month')" :class="{active: billType === 'month'}">Monthly billing</button>
				<button type="button" @click="billSwitch('year')" :class="{active: billType === 'year'}">Yearly <span>Save 18%</span></button>
				<div class="animation_active" :style="{left: activePosition + 'px', width: '50%'}" :class="billType"></div>
			</div>
			<div v-else class="bill_switch_skeleton"></div>


			<div class="wrap_memberships" v-if="!LOADER_GET_ALL_SUBSCRIPTIONS && !LOADER_GET_SUBSCRIPTION && !loader">

				<!-- Free -->
				<div class="item_membership free" :class="{'active': USER_PERMISSIONS === 'free', 'first_login': AUTH_DATA.firstLogin}">
					<div class="inside">
						<div class="membership_title">
							Free
							<span>All the necessities</span>
						</div>
						<div class="description">
							<span>5 Free downloads x day</span>
							<span>Lower quality streaming</span>
							<span>Discover tab</span>
							<span>Unlimited purchases</span>
						</div>

						<div class="membership_action" v-if="USER_PERMISSIONS">
							<template v-if="USER_PERMISSIONS === 'free'">
								<button type="button" class="current_plan" v-if="AUTH_DATA.firstLogin !== true">
									Current Plan
								</button>
								<button type="button" class="downgrade" v-if="AUTH_DATA.firstLogin === true" @click="freeContinue">
									Continue
								</button>
							</template>
							<template v-if="USER_PERMISSIONS === 'premium'">
								<button class="free_begins"
								        v-if="SUBSCRIPTION && SUBSCRIPTION.status === 'canceled' && ALL_SUBSCRIPTION && allSubscriptionsLength === 1">
									Begins on {{ SUBSCRIPTION.currentPeriodEnd | moment('MMM DD, YYYY') }}
								</button>
								<button v-else type="button" class="downgrade" @click="confirmDowngrade()" v-ripple
								        :class="{loading: LOADER_REMOVE_SUBSCRIPTION}"
                                        :disabled="LOADER_REMOVE_SUBSCRIPTION">
									<v-progress-circular :width="2" indeterminate></v-progress-circular>
									Downgrade
								</button>
							</template>
							<template v-if="USER_PERMISSIONS === 'founder'">
								<div class="lifetime name_box">Lifetime Founder's Club</div>
							</template>
						</div>
					</div>
				</div>

				<!-- Premium -->
				<div class="item_membership premium" :class="{'active': USER_PERMISSIONS === 'premium'}">
					<div class="inside">
						<div class="membership_title">
							Premium
							<span>All the necessities and all the nicities</span>
							<div class="price">
								<template v-if="billType === 'month'">
									${{COMMISSION_RATES.user_subscription_month}}/month
									<span>Billed monthly</span>
								</template>
								<template v-if="billType === 'year'">
									${{(COMMISSION_RATES.user_subscription_year)}}/year
									<span>Billed once a year</span>
								</template>
							</div>
						</div>

						<div class="description">
							<span>High Quality Streaming</span>
							<span>50 Free downloads x day</span>
							<span>20 Suggestion votes (soon)</span>
							<span>Bookmarks (soon)</span>
							<span>Ability to comment</span>
							<span>List view</span>
							<span>Premium badge</span>
							<span>No Ads</span>
						</div>

						<div class="membership_action">
							<button v-if="USER_PERMISSIONS === 'free'" type="button" v-ripple class="c2a_btn small free_days_trial"
							        @click="createNewSubscription('premium', billType)"
							        :disabled="LOADER_ADD_SUBSCRIPTION"
							        :class="{loading: LOADER_ADD_SUBSCRIPTION}">
								<v-progress-circular :width="2" indeterminate></v-progress-circular>
								<template v-if="PROFILE && PROFILE.haveFreeSubscription === true">
                  Sign Up
                </template>
								<template v-else>Sign Up</template>
								<span class="arrow">
                                        <span class="line_svg"></span>
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                        </svg>
                                    </span>
							</button>

							<template v-if="USER_PERMISSIONS === 'premium'">

										<!-- Monthly -->
										<template v-if="billType === 'month'">

											<template v-if="Object.values(ALL_SUBSCRIPTION).some(el => el.type === 'month')">
												<div v-for="plan of ALL_SUBSCRIPTION" :key="plan.id">
													<template v-if="SUBSCRIPTION.plan === 'premium' && plan.type === 'month'">

														<template v-if="plan.status === 'pending' || plan.status === 'active' || plan.status === 'trialing'">
															<div class="current_plan">Current Plan</div>
															<div class="renews">Renews on {{ plan.currentPeriodEnd | moment('MMM DD') }}</div>
														</template>

														<template v-if="plan.status === 'canceled'">
															<template v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 1">
																<button type="button" class="c2a_btn small" @click="unCancel" 
                                                                        :class="{loading: LOADER_REACTIVATE_SUBSCRIPTION}"
                                                                        :disabled="LOADER_REACTIVATE_SUBSCRIPTION">
																	<v-progress-circular :width="2" indeterminate></v-progress-circular>
                                  Reinstate
																	<span class="arrow">
					                                                <span class="line_svg"></span>
					                                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
					                                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
					                                                </svg>
					                                            </span>
																</button>
															</template>
															<template v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 2">
																<button type="button" v-ripple class="c2a_btn small free_days_trial" 
                                                                        :class="{loading: LOADER_ADD_SUBSCRIPTION}"
                                                                        :disabled="LOADER_ADD_SUBSCRIPTION"
																        @click="confirmRemoveThisSubscription('monthly')">
																	<v-progress-circular :width="2" indeterminate></v-progress-circular>
																	Switch to Monthly
																	<span class="arrow">
						                                            <span class="line_svg"></span>
						                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
						                                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
						                                            </svg>
						                                        </span>
																</button>
															</template>
														</template>

													</template>
												</div>
											</template>
											<template v-else>
												<button type="button" v-ripple class="c2a_btn small free_days_trial"
												        @click="confirmChangeInterval('month')" 
                                                        :class="{loading: LOADER_ADD_SUBSCRIPTION}"
                                                        :disabled="LOADER_ADD_SUBSCRIPTION">
													<v-progress-circular :width="2" indeterminate></v-progress-circular>
													Switch to Monthly
													<span class="arrow">
			                                            <span class="line_svg"></span>
			                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
			                                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
			                                            </svg>
			                                        </span>
												</button>
											</template>

										</template>


										<!-- Yearly -->
										<template v-if="billType === 'year'">
											<template v-if="Object.values(ALL_SUBSCRIPTION).some(el => el.type === 'year')">
												<div v-for="plan of ALL_SUBSCRIPTION" :key="plan.id">

													<template v-if="SUBSCRIPTION.plan === 'premium' && plan.type === 'year'">
														<template v-if="plan.status === 'pending' || plan.status === 'active' || plan.status === 'trialing'">
															<div class="current_plan">Current Plan</div>
															<div class="renews">Renews on {{ plan.currentPeriodEnd | moment('MMM DD') }}</div>
														</template>

														<template v-if="plan.status === 'canceled'">
															<template v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 1">
																<button type="button" class="c2a_btn small" @click="unCancel" 
                                                                        :class="{loading: LOADER_REACTIVATE_SUBSCRIPTION}"
                                                                        :disabled="LOADER_REACTIVATE_SUBSCRIPTION">
																	<v-progress-circular :width="2" indeterminate></v-progress-circular>
                                  Reinstate
																	<span class="arrow">
					                                                <span class="line_svg"></span>
					                                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
					                                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
					                                                </svg>
					                                            </span>
																</button>
															</template>
															<template v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 2">
																<button type="button" v-ripple class="c2a_btn small free_days_trial"
																        @click="confirmRemoveThisSubscription('yearly')" 
                                                                        :class="{loading: LOADER_ADD_SUBSCRIPTION}"
                                                                        :disabled="LOADER_ADD_SUBSCRIPTION">
																	<v-progress-circular :width="2" indeterminate></v-progress-circular>
																	Switch to Yearly
																	<span class="arrow">
						                                            <span class="line_svg"></span>
						                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
						                                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
						                                            </svg>
						                                        </span>
																</button>
															</template>
														</template>
													</template>
												</div>
											</template>
											<template v-else>
												<button type="button" v-ripple class="c2a_btn small free_days_trial"
												        @click="confirmChangeInterval('year')" 
                                                        :class="{loading: LOADER_ADD_SUBSCRIPTION}"
                                                        :disabled="LOADER_ADD_SUBSCRIPTION">
													<v-progress-circular :width="2" indeterminate></v-progress-circular>
													Switch to Yearly
													<span class="arrow">
			                                            <span class="line_svg"></span>
			                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
			                                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
			                                            </svg>
			                                        </span>
												</button>
											</template>
										</template>


							</template>
							<template v-if="USER_PERMISSIONS === 'founder'">
								<div class="lifetime name_box">Lifetime Founder's Club</div>
							</template>
						</div>
					</div>
				</div>

				<!-- Founder`s Club-->
				<div class="item_membership founders" :class="{'active': USER_PERMISSIONS === 'founder'}">
					<div class="inside">
						<div class="membership_title">
							Founder's Club
							<span>Limited offer</span>
							<div class="price" v-if="LIMIT_FOUNDERS">
								{{ LIMIT_FOUNDERS.available }}/{{ LIMIT_FOUNDERS.total }}
								<span>available</span>
							</div>
						</div>
						<div class="donation">
							<div class="t_">${{COMMISSION_RATES.founder_fee}} Donation</div>
							<div class="d_">One time investment</div>
						</div>

						<div class="description">
							<span>Lifetime Premium membership</span>
							<span>Exclusive Founder Role</span>
							<span>Plus more</span>
						</div>
						<div class="membership_action">

							<template v-if="USER_PERMISSIONS === 'founder'">
								<button type="button" class="current_plan claim_your_seat c2a_btn">
									<span class="inside">Current Plan</span>
								</button>
							</template>
							<template v-else>
								<button type="button" class="claim_your_seat" @click="claimYourSeat" 
										:class="{ loading: LOADER_ADD_FOUNDERS_SUBSCRIPTION }"
										:disabled="LOADER_ADD_FOUNDERS_SUBSCRIPTION">
									<div class="inside">
										<v-progress-circular v-if="LOADER_ADD_FOUNDERS_SUBSCRIPTION" :width="2" indeterminate></v-progress-circular>
										<template v-else>
											Claim Your Seat
										</template>
									</div>
								</button>
							</template>
						</div>
					</div>
				</div>

			</div>
<!--			<div class="plans_skeleton" v-if="LOADER_GET_ALL_SUBSCRIPTIONS || LOADER_GET_SUBSCRIPTION">-->
			<div class="plans_skeleton" v-else>
				<albumGridSkeletonLoader class="free_skeleton_plan" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
				<albumGridSkeletonLoader class="premium_skeleton_plan" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
				<albumGridSkeletonLoader class="founder_skeleton_plan" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
			</div>

			<div class="frequently_asked_questions">
				<div class="heading">
					<div class="title_">Frequently Asked Questions</div>
					<div class="subtitle_">
						Have more questions? Send us an <a href="mailto:admin@psychurch.org" target="_blank">email</a> or visit our <a
						href="https://discord.gg/scABv4gpgw" target="_blank">#support</a> channel for help.
					</div>
				</div>

				<div class="item_question" v-for="(item, index) in faqs" :key="index">
					<div class="question" @click="item.show = !item.show" :class="{ active: item.show }">
						{{ item.question }}
						<div class="visible"></div>
					</div>
					<div class="answer" :class="{ active: item.show }">
						{{ item.answer }}
					</div>
				</div>
			</div>

			<!-- Remove Subscription -->
			<v-dialog
				v-if="modalDowngrade"
				v-model="modalDowngrade"
				max-width="546"
				overlay-color="#0C1324"
				overlay-opacity="0.9"
				:content-class="'subscriptions_plan_modal'">
				<h2 class="modal_header">
					Downgrade
				</h2>
				<div class="modal_content">
					You are about to cancel your premium subscription. You can still use premium features until
					<span v-if="SUBSCRIPTION">{{ SUBSCRIPTION.currentPeriodEnd | moment("MMM DD, YYYY") }}</span> after which your subscription will not
					renew.
				</div>
				<div class="modal_wrap_actions">
					<button type="button" class="keep_it" v-ripple @click="modalDowngrade = false">Keep it</button>
					<button v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 1" type="button" class="unsubscribe" v-ripple @click="removeThisSubscription"
					        :class="{loading: LOADER_REMOVE_SUBSCRIPTION}"
                            :disabled="LOADER_REMOVE_SUBSCRIPTION">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Unsubscribe
					</button>
					<button v-if="ALL_SUBSCRIPTION && allSubscriptionsLength === 2" type="button" class="unsubscribe" v-ripple
					        @click="downgradeThisSubscription" :class="{loading: LOADER_REMOVE_SUBSCRIPTION}"
                            :disabled="LOADER_REMOVE_SUBSCRIPTION">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Unsubscribe
					</button>
				</div>
			</v-dialog>

			<!-- Switch Interval -->
			<v-dialog
				v-model="changeInterval.modal"
				max-width="546"
				overlay-color="#0C1324"
				overlay-opacity="0.9"
				:content-class="'subscriptions_plan_modal'">
				<h2 class="modal_header">
					<template v-if="changeInterval.interval === 'month' || changeInterval.interval === 'monthly'">Yearly &#8594; Monthly</template>
					<template v-if="changeInterval.interval === 'year' || changeInterval.interval === 'yearly'">Monthly &#8594; Yearly</template>
				</h2>
				<div class="modal_content">
					<template v-if="changeInterval.interval === 'month' || changeInterval.interval === 'monthly'">
						You are about to switch your yearly premium subscription to a monthly one.
						Your yearly subscription ends on <span v-if="SUBSCRIPTION">{{ SUBSCRIPTION.currentPeriodEnd | moment("MMM DD, YYYY") }}</span>
						after which you will be billed ${{COMMISSION_RATES.user_subscription_month}} on a monthly basis.
					</template>

					<template v-if="changeInterval.interval === 'year' || changeInterval.interval === 'yearly'">
						You are about to switch your monthly premium subscription to a yearly one.
						Your monthly subscription ends on <span v-if="SUBSCRIPTION">{{ SUBSCRIPTION.currentPeriodEnd | moment("MMM DD, YYYY") }}</span>
						after which you will be billed ${{COMMISSION_RATES.user_subscription_year}} on a yearly basis.
					</template>
				</div>
				<div class="modal_wrap_actions">
					<button type="button" class="keep_it" v-ripple @click="changeInterval.modal = false">
						<template v-if="changeInterval.interval === 'month' || changeInterval.interval === 'monthly'">Keep Yearly</template>
						<template v-if="changeInterval.interval === 'year' || changeInterval.interval === 'yearly'">Keep Monthly</template>
					</button>
					<button type="button" class="unsubscribe" v-ripple @click="switchSubscriptionInterval(changeInterval.interval)"
					        v-if="changeInterval.interval !== 'yearly' && changeInterval.interval !== 'monthly'"
					        :class="{loading: LOADER_ADD_SUBSCRIPTION}"
                            :disabled="LOADER_ADD_SUBSCRIPTION">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Switch
					</button>
					<button type="button" class="unsubscribe" v-ripple @click="removeThisSubscription"
					        v-if="changeInterval.interval === 'yearly' || changeInterval.interval === 'monthly'"
					        :class="{loading: LOADER_REMOVE_SUBSCRIPTION}"
                            :disabled="LOADER_REMOVE_SUBSCRIPTION">
						<v-progress-circular :width="2" indeterminate></v-progress-circular>
						Switch
					</button>
				</div>
			</v-dialog>

		</v-container>
		<comingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></comingSoonDialog>
		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import comingSoonDialog from "@/components/dialogs/comingSoonDialog.vue";
import errorDialog from "@/components/dialogs/error.vue";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";


export default {
	name: 'memberships',
	components: {
		comingSoonDialog,
		errorDialog,
		albumGridSkeletonLoader
	},
	data() {
		return {
			currentPlan: '',
			showComingSoonDialog: false,
			billType: '',
			faqs: [
				{
					question: 'Why go premium?',
					answer: 'On top of getting all of the perks listed above, you\'ll be the driving force behind the development of new features and the oh so necessary bug fixes. If you are as passionate about this project as we are, this is one of the best ways you can contribute.',
					show: false
				},
				{
					question: 'How do cancellations work?',
					answer: 'When you cancel your subcsciption, your service will continue until the end of your contracted term at which point it will not renew.',
					show: false
				},
				{
					question: 'Exclusive Founder\'s Club',
					answer: 'Founder\'s Club is a limited availability offer that aims to give our first investors a lifetime access to premium features and distinguish them with a special \n' +
						'role; Founder. On top of these perks Founders will have access to many other goodies we decide to come up with in the future. In other words, we will be eternally grateful for your contribution!',
					show: false
				},
			],
			faqsDynamicKey: 0,
			modalDowngrade: false,
			modalChangeInterval: false,
			changeInterval: {
					modal: false,
					interval: null,
			},
			isFounder: false,
			allSubscriptionsLength: null,
			errorDialog: false,
			loader: false,
			activePosition: '',
			activeWidth: '',
		}
	},
	watch: {
		PROFILE_LOADER() {
			if (this.PROFILE_LOADER === false && this.USER_PERMISSIONS === 'premium') {
				this.getSubscriptionData();
			}
		}
	},
	computed: {
		...mapGetters([
			'AUTH_DATA',
			'USER_PERMISSIONS',
			'PROFILE_LOADER',
			'COMMISSION_RATES',
			'PROFILE', 'SUBSCRIPTION_LINK', 'SUBSCRIPTION', 'ALL_SUBSCRIPTION', 'LIMIT_FOUNDERS', 'CREATE_SUBSCRIPTION_DATA',
			'LOADER_GET_SUBSCRIPTION',
			'LOADER_ADD_SUBSCRIPTION', // *
			'LOADER_ADD_FOUNDERS_SUBSCRIPTION',
			'LOADER_REMOVE_SUBSCRIPTION', // *
			'LOADER_REACTIVATE_SUBSCRIPTION', // *
			'LOADER_GET_ALL_SUBSCRIPTIONS'

		]),
	},
	mounted() {
		this.getSubscriptionData();
		console.log('AUTH_DATA', this.AUTH_DATA);
	},
	methods: {
		...mapActions({
			getCommissionRates: 'GET_COMMISSION_RATES',
			// get
			getProfile: 'GET_PROFILE',
			getSubscription: 'GET_SUBSCRIPTION',
			getAllSubscriptions: 'GET_ALL_SUBSCRIPTIONS',
			checkLimitFounders: 'CHECK_LIMIT_FOUNDERS',

			// add
			addSubscription: 'ADD_SUBSCRIPTION',
			addFoundersSubscription: 'ADD_FOUNDERS_SUBSCRIPTION',
			// changeSubscription: 'CHANGE_SUBSCRIPTION',

			// remove && reactivate
			removeSubscription: 'REMOVE_SUBSCRIPTION',
			downgradeSubscription: 'DOWNGRADE_SUBSCRIPTION',
			reactivateSubscription: 'REACTIVATE_SUBSCRIPTION'
		}),
		billSwitch(type) {
			this.billType = type;
			
			// Progressive enhancement: Try to vibrate on supported devices
			try {
				if ('vibrate' in navigator) {
					// Pattern: vibrate 50ms, pause 20ms, vibrate 50ms, pause 20ms, vibrate 50ms
					navigator.vibrate([50, 20, 50, 20, 50]);
				}
			} catch (e) {
				// Silently fail if vibration isn't supported
				console.debug('Vibration not supported');
			}
			
			this.$nextTick(() => {
				const cards = document.querySelectorAll('.item_membership');
				cards.forEach((card, index) => {
					// Remove any existing animations
					card.style.animation = 'none';
					card.offsetHeight; // Trigger reflow
					// Add new animation without position/overflow styles
					card.style.animation = `card-switch 0.5s ease-out ${index * 0.1}s`;
				});
			});
		},
		confirmDowngrade() {
			this.modalDowngrade = true;
		},
		confirmChangeInterval(interval) {
			this.changeInterval.modal = true;
			this.changeInterval.interval = interval;
		},
		confirmRemoveThisSubscription(interval) {
			this.changeInterval.modal = true;
			this.changeInterval.interval = interval;
		},

		getSubscriptionData() {
			this.loader = true;
			this.getCommissionRates()
				.then(() => {

				})
				.catch(err => {
					this.errorDialog = true;
					console.error(`memberships, GET_COMMISSION_RATES', ${err}`);
				})
			setTimeout(() => {
				this.getSubscription()
					.then(() => {
						this.getAllSubscriptionsData();
					})
					.catch(err => console.error(`memberships, GET_SUBSCRIPTION', ${err}`));
				this.getCheckLimitFounders();
			}, 500);
		},
		getAllSubscriptionsData() {
			this.getAllSubscriptions()
				.then(() => {
					this.allSubscriptionsLength = Object.keys(this.ALL_SUBSCRIPTION).length;
					let month = Object.values(this.ALL_SUBSCRIPTION).some(el => el.status === 'pending' && el.type === 'month');
					let year = Object.values(this.ALL_SUBSCRIPTION).some(el => el.status === 'pending' && el.type === 'year');

					if (month === true) this.billType = 'month';
					if (year === true) this.billType = 'year';
					if (!month && !year) this.billType = this.SUBSCRIPTION.type || 'month';
					this.loader = false;
				})
				.catch(err => console.error(`memberships, GET_ALL_SUBSCRIPTIONS', ${err}`))
		},
		getCheckLimitFounders() {
			this.checkLimitFounders()
				.catch(err => console.error(`memberships, CHECK_LIMIT_FOUNDERS', ${err}`));
		},
		removeThisSubscription() {
			this.removeSubscription()
				.then(() => {
					this.modalDowngrade = false;
					this.changeInterval.modal = false;
					this.getSubscriptionData();
				})
				.catch(err => console.error(`removeThisSubscription, ${err}`));
		},
		downgradeThisSubscription() {
			this.downgradeSubscription()
				.then(() => {
					this.modalDowngrade = false;
					this.getSubscriptionData();
				})
				.catch(err => console.error(`downgradeThisSubscription, ${err}`));
		},
		createNewSubscription(plan, interval) {
			this.addSubscription({plan: plan, interval: interval})
				.then(() => {
					if (this.CREATE_SUBSCRIPTION_DATA && this.CREATE_SUBSCRIPTION_DATA.clientSecret) {
						this.$router.push({
							name: 'membershipCheckOut',
							params: {
								clientSecret: this.CREATE_SUBSCRIPTION_DATA.clientSecret,
								membership: JSON.stringify(this.CREATE_SUBSCRIPTION_DATA.membership)
							}
						});
					}

					// console.log('createNewSubscription');
					// this.$router.push({
					// 	name: 'membershipCheckOut',
					// 	params: {
					// 		clientSecret: this.CREATE_SUBSCRIPTION_DATA.clientSecret,
					// 		membership: JSON.stringify(this.CREATE_SUBSCRIPTION_DATA.membership)
					// 	}
					// });
					// window.open(this.SUBSCRIPTION_LINK, '_blank'); // TODO: redirect to checkOutMembership page
					this.getSubscriptionData();
				})
				.catch(err => console.error(`createNewSubscription, ${err}`));
		},

		switchSubscriptionInterval(newInterval) {
				this.addSubscription({interval: newInterval, plan: this.USER_PERMISSIONS})
					.catch(err => console.error(`switchSubscriptionInterval, ${err}`))
					.finally(() => {
							this.getSubscriptionData();
							setTimeout(() => {
								this.changeInterval.modal = false;
							}, 500);
					})
		},
		claimYourSeat() {
			this.addFoundersSubscription()
				.then(() => {
					// window.open(this.SUBSCRIPTION_LINK, '_blank'); // TODO: redirect to checkOutMembership page
					// this.$router.push({name: 'checkOutMembership'}); // CREATE_SUBSCRIPTION_DATA
					// this.$router.push({ name: 'membershipCheckOut', params: { clientSecret: this.CREATE_SUBSCRIPTION_DATA } });
					this.$router.push({
						name: 'membershipCheckOut',
						params: {
							clientSecret: this.CREATE_SUBSCRIPTION_DATA.clientSecret,
							membership: JSON.stringify(this.CREATE_SUBSCRIPTION_DATA.membership)
						}
					});
					this.getSubscriptionData();
				})
				.catch(err => console.error(`claimYourSeat, ${err}`));
		},
		unCancel() {
			this.reactivateSubscription({plan: this.USER_PERMISSIONS})
				.catch(err => console.error(`unCancel, ${err}`))
				.finally(() => {
					this.getSubscriptionData();
				})
		},
		freeContinue() {
			this.$router.push({name: 'private-profile'});
		},
		openQuestion(item) {
			item.show = !item.show;
			this.faqsDynamicKey++;
		},
		activeSubgenre() {
			if (!this.mobile) {
				this.$nextTick(() => {
					let active = this.$refs['subgenrePosition'].find(el => el._prevClass === 'active');
					if (active) {
						this.activeSubgenrePosition = active.offsetLeft;
						this.activeSubgenreWidth = active.offsetWidth;
					} else {
						this.activeSubgenrePosition = 6;
						this.activeSubgenreWidth = 70;
					}
				});
			}
		}
	}
}
</script>

<style lang="scss">

</style>

