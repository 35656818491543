<template>
	<userProfileLayout>
		<div class="user_profile" data-page="user-profile">
			<div class="head_profile">
				<!-- Background div should be first child of head_profile -->
				<div 
					class="profile_head_background"
					:class="{'default': !backgroundGradient}"
					:style="backgroundGradient ? {
						background: backgroundGradient
					} : null">
				</div>

				<!-- Loader -->
				<div class="profile-header-container" v-if="loader">
					<headSkeletonLoader parent="user-profile" />
				</div>
				
				<!-- Content -->
				<div class="profile-header-container" v-else>
					<userProfileAvatar
						:userLogo="PROFILE_USER_LOGO"
						:isLoading="loader"
						:routeParams="$route.params"
						@avatar-updated="handleAvatarUpdate"
						@avatar-reset="handleAvatarReset"
						@update-cache-key="handleCacheKeyUpdate"
						@show-modal="showAvatarModal"
					/>

					<div class="user_info">
						<div class="user_name">
							<div class="name-badge-wrapper">
								{{ PROFILE_USER.fullName }}
								<template v-if="PROFILE_USER && (PROFILE_USER.subscription === 'premium' || PROFILE_USER.isFounder === true)">
									<v-tooltip top close-delay="0" transition="false">
										<template v-slot:activator="{ on, attrs }">
											<div class="profile_badge" v-bind="attrs" v-on="on"
												 :class="{'premium': PROFILE_USER.subscription === 'premium', 'founder': PROFILE_USER.isFounder === true}">
											</div>
										</template>
										<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === false">Premium account</div>
										<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === true">Founder's account</div>
									</v-tooltip>
								</template>
							</div>
						</div>

						<div class="user_location" v-if="PROFILE_USER.address">
							{{ PROFILE_USER.address }}
						</div>

						<div class="follows">
							<div class="followers">
								<span class="count">{{ PROFILE_USER.followersCount }}</span>
								<div>Followers</div>
							</div>
							<div class="following">
								<span class="count">{{ PROFILE_USER.followingCount }}</span>
								<div>Following</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="wrap_profile_music_events">
				<div class="head_profile_music_events">
					<v-container>
						<v-tabs
							background-color="transparent"
							color="#fff"
							v-model="tabs">
							<v-tab v-for="tab in tabsSections" :key="tab.index">
								{{ tab.name }}
							</v-tab>
						</v-tabs>
						<div class="actions">
							<template v-if="loader || actionButtonsLoader">
								<button class="action-button skeleton-loader" :key="'action-skeleton-1'"></button>
								<button class="action-button skeleton-loader" :key="'action-skeleton-2'"></button>
								<button class="action-button skeleton-loader" :key="'action-skeleton-3'"></button>
							</template>

							<template v-if="actionButtonsLoader === false && this.AUTH_DATA.accessToken">
								<!-- Follow Button -->
								<template v-if="followBtnLoader">
									<div class="skeleton-loader action-button-skeleton"></div>
								</template>
								<template v-else>
									<button type="button" class="follow_btn" v-if="this.isFollow === false" @click="follow(PROFILE_USER.id)">
										<span class="icon"></span>
										Follow
									</button>
									<button type="button" class="follow_btn following" v-else @click="unfollow(PROFILE_USER.id)" 
										@mouseover="hoverFollowingButton = true"
										@mouseleave="hoverFollowingButton = false">
										<span class="icon"></span>
										<template v-if="hoverFollowingButton === true">Unfollow</template>
										<template v-else>Following</template>
									</button>
								</template>

								<button type="button" class="message_btn" @click="showComingSoonDialog = true">
									<span class="icon"></span>
									Message
								</button>
								<button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
									<span class="material-icons" style="margin-right: 8px;">share</span>
									Share
								</button>
								<button v-if="copiedUserLink" type="button" class="share_btn">
									<span class="material-icons" style="margin-right: 8px;">check</span>
									Copied!
								</button>
							</template>

							<template v-if="actionButtonsLoader === false && !this.AUTH_DATA.accessToken">
								<button type="button" class="follow_btn" @click="dialogNeedLoginVisible = true">
									<span class="icon"></span>
									Follow
								</button>
								<button type="button" class="message_btn" @click="dialogNeedLoginVisible = true">
									<span class="icon"></span>
									Message
								</button>
								<button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
									<span class="material-icons" style="margin-right: 8px;">share</span>
									Share
								</button>
								<button v-if="copiedUserLink" type="button" class="share_btn">
									<span class="material-icons" style="margin-right: 8px;">check</span>
									Copied!
								</button>
							</template>

							<!-- More menu -->
							<v-menu
								transition="slide-y-transition"
								:close-on-content-click="true"
								offset-y
								nudge-left="21"
								nudge-bottom="10"
								origin="top center"
								content-class="menu-pointer charcoal">
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
										<span class="material-icons">more_vert</span>
									</v-btn>
								</template>
								<v-list>
									<v-list-item>
										<button type="button" v-ripple class="report">Report</button>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</v-container>
				</div>

				<div class="content_profile_music_events">
					<v-tabs-items touchless v-model="tabs">
						<!-- Music Tab -->
						<v-tab-item :transition="false">
							<div class="music_section">
								<div class="recently_liked">
									<div class="container">
										<div class="head_profile_content">
											<ContentHeader>
												<template #title>Recently liked</template>
												<template #action v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
													<v-btn outlined @click="showComingSoonDialog = true">Library</v-btn>
												</template>
											</ContentHeader>
										</div>
										<div class="wrap_music">
											<template v-if="recentlyLikedAlbumsLoader">
												<albumGridSkeletonLoader v-for="index in 6" :key="index"
												                         :parent="'default_grid_albums'"></albumGridSkeletonLoader>
											</template>
											<template v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
												<albumGrid v-for="album in USER_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
												           :key="album.id"
												           :album="album"
												           :playZone="USER_RECENTLY_LIKED_ALBUMS">
												</albumGrid>
											</template>
										</div>
										<nothingHereYet v-if="!recentlyLikedAlbumsLoader && !USER_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
									</div>
								</div>
							</div>
						</v-tab-item>

						<!-- Events Tab -->
						<v-tab-item :transition="false">
							<div class="events_section">
								<div class="uploaded_events">
									<v-container>
										<div class="head_profile_content">
											<ContentHeader>
												<template #title>Coming up</template>
											</ContentHeader>
										</div>
										<nothingHereYet></nothingHereYet>
									</v-container>
								</div>
							</div>
						</v-tab-item>

						<!-- About Tab -->
						<v-tab-item :transition="false">
							<div class="about_section">
								<v-container v-if="hasAnyRole">
									<div class="wrap_about_section">
										<div class="user-roles">
											<div class="role-item" v-if="PROFILE_USER.isProducer">
												<span class="icon">🎵</span> Producer
											</div>
											<div class="role-item" v-if="PROFILE_USER.isPromoter">
												<span class="icon">📣</span> Promoter
											</div>
											<div class="role-item" v-if="PROFILE_USER.isVendor">
												<span class="icon">🏷️</span> Vendor
											</div>
											<div class="role-item" v-if="PROFILE_USER.isDecoArtist">
												<span class="icon">🎨</span> Deco Artist
											</div>
											<div class="role-item" v-if="PROFILE_USER.isDj">
												<span class="icon">🎧</span> DJ
											</div>
										</div>
									</div>
								</v-container>

								<v-container class="v_container_info">
									<div class="head_profile_content">
										<ContentHeader>
											<template #title>Info</template>
										</ContentHeader>
									</div>
									<div class="wrap_about_section">
										<div class="artist_projects" v-if="USER_ARTISTS.length">
											<div class="label_">Projects:</div>
											<div class="list_projects">
												<div class="projects" v-for="artist in USER_ARTISTS" :key="artist.id">
													<div @click="redirectToArtistPage(artist.id, artist.identifier)">
														{{ artist.name.trim() }}
													</div>
													<span v-if="USER_ARTISTS.length > 1">,</span>
												</div>
											</div>
										</div>
									</div>
								</v-container>

								<v-container>
									<div class="head_profile_content">
										<ContentHeader>
											<template #title>Bio</template>
										</ContentHeader>
									</div>
									<div class="wrap_about_section">
										<div class="_location" v-if="PROFILE_USER.address">
											{{ PROFILE_USER.address }}
										</div>
										<div class="_description" v-if="PROFILE_USER.description" v-html="formattedBio">
										</div>
										<template v-else>
											<nothingHereYet></nothingHereYet>
										</template>
									</div>
								</v-container>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>

			<!-- Dialogs -->
			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog" />
			<dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible" />
			<errorDialog v-if="errorDialog" v-model="errorDialog" />
			<modalImage 
				ref="modal-image" 
				:image="avatarData.original ? `${avatarData.original}?cache=${avatarData.key}` : ''"
				@update-cache-key="handleCacheKeyUpdate"
			/>
		</div>
	</userProfileLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userProfileLayout from '@/layouts/profiles/userProfileLayout.vue';
import albumGrid from '@/components/musicReleases/albumGrid.vue';
import headSkeletonLoader from '@/components/small/skeleton-loaders/headSkeletonLoader.vue';
import nothingHereYet from '@/components/small/nothingHereYet.vue';
import actionButtonSkeletons from '@/mixins/actionButtonSkeletons';
import ContentHeader from '@/components/ContentHeader.vue';
import userProfileAvatar from '@/components/profile/userProfileAvatar.vue';

const ComingSoonDialog = () => import('@/components/dialogs/comingSoonDialog.vue');
const dialogNeedLogin = () => import('@/components/dialogs/needLogin.vue');
const errorDialog = () => import('@/components/dialogs/error.vue');
const modalImage = () => import("@/components/public/modalImage");

import isMobile from '@/mixins/isMobile';
import checkAccessToken from '@/mixins/checkAccessToken';
import albumGridSkeletonLoader from '@/components/small/skeleton-loaders/albumGridSkeletonLoader.vue';
import { extractColorsFromImage } from '@/utils/imageUtils';
import store from '@/store';

export default {
    name: 'user-profile',
    props: ['id', 'name'],
    components: {
        userProfileLayout,
        albumGrid,
        ComingSoonDialog,
        dialogNeedLogin,
        errorDialog,
        headSkeletonLoader,
        albumGridSkeletonLoader,
        nothingHereYet,
        ContentHeader,
        userProfileAvatar,
        modalImage
    },
    mixins: [checkAccessToken, isMobile, actionButtonSkeletons],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            tabs: null,
            tabsSections: [
                {index: 0, name: 'Music'},
                {index: 1, name: 'Events'},
                {index: 2, name: 'About'}
            ],
            shareLink: `${window.location.hostname}/user/${this.id}`,
            showComingSoonDialog: false,
            dialogNeedLoginVisible: false,
            errorDialog: false,
            loader: false,
            recentlyLikedAlbumsLoader: false,
            isFollow: false,
            hoverFollowingButton: false,
            actionButtonsLoader: false,
            followBtnLoader: false,
            messageBtnLoader: false,
            supportBtnLoader: false,
            copiedUserLink: false,
            avatarData: {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            },
            backgroundGradient: null,
        }
    },
    computed: {
        ...mapGetters([
            'AUTH_DATA',
            'PROFILE_USER',
            'USER_RECENTLY_LIKED_ALBUMS',
            'USER_ARTISTS',
            'HAS_FOLLOWED_USER',
            'USER_LOGO',
            'PROFILE_USER_LOGO'
        ]),
        formattedBio() {
            return this.PROFILE_USER.description ? this.PROFILE_USER.description.replace(/\n/g, '<br>') : '';
        },
        hasAnyRole() {
            return this.PROFILE_USER?.isProducer ||
                this.PROFILE_USER?.isPromoter ||
                this.PROFILE_USER?.isVendor ||
                this.PROFILE_USER?.isDecoArtist ||
                this.PROFILE_USER?.isDj;
        }
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler() {
                // Remove this or modify to not trigger getUserData
                // this.getUserData();
            }
        },
        PROFILE_USER: {
            handler(newUser) {
                if (newUser) {
                    // Clear previous avatar data first
                    this.avatarData = {
                        isDefault: true,
                        smallUrl: '',
                        srcset: '',
                        original: '',
                        key: new Date().getTime()
                    };
                    
                    if (newUser.logo) {
                        this.avatarData = {
                            isDefault: false,
                            smallUrl: newUser.logo.small,
                            srcset: newUser.logo.srcset,
                            original: newUser.logo.original,
                            key: new Date().getTime()
                        };
                    }
                } else {
                    this.resetAvatarData();
                }
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'GET_PROFILE_USER',
            'GET_HAS_FOLLOWED_USER',
            'GET_USER_ARTISTS',
            'GET_USER_RECENTLY_LIKED_ALBUMS',
            'FOLLOW',
            'UNFOLLOW',
        ]),
        handleAvatarUpdate(newAvatarData) {
            const backgroundElement = this.$el.querySelector('.profile_head_background');
            if (backgroundElement) {
                backgroundElement.classList.remove('visible');
                backgroundElement.classList.remove('default');
            }

            this.avatarData = { ...newAvatarData };
            if (!newAvatarData.isDefault) {
                this.updateBackgroundGradient(`${newAvatarData.smallUrl}?cache=${newAvatarData.key}`);
            } else {
                this.setDefaultImage();
            }
        },
        handleAvatarReset() {
            this.avatarData = {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            };
            this.backgroundGradient = null;
        },
        handleCacheKeyUpdate(newKey) {
            this.avatarData.key = newKey;
            if (!this.avatarData.isDefault) {
                this.updateBackgroundGradient(`${this.avatarData.smallUrl}?cache=${newKey}`);
            }
        },
        getUserData() {
            if (!this.PROFILE_USER?.id) {
                return;
            }

            this.loader = true;
            this.actionButtonsLoader = true;
            this.recentlyLikedAlbumsLoader = true;

            const promises = [];

            if (this.AUTH_DATA.accessToken) {
                promises.push(
                    this.GET_HAS_FOLLOWED_USER(this.PROFILE_USER.id)
                        .then(() => {
                            this.isFollow = this.HAS_FOLLOWED_USER;
                        })
                        .catch(() => null)
                );
            }

            promises.push(
                this.getRecentlyLikedAlbums(this.PROFILE_USER.id)
                    .catch(() => [])
            );
            
            promises.push(
                this.getUserArtists(this.PROFILE_USER.id)
                    .catch(() => [])
            );

            return Promise.all(promises)
                .catch(() => {})
                .finally(() => {
                    this.loader = false;
                    this.actionButtonsLoader = false;
                    this.recentlyLikedAlbumsLoader = false;
                });
        },

        redirectToArtistPage(artistId, artistName) {
            this.$router.push({
                name: 'artist-profile',
                params: {
                    id: artistId,
                    name: window.toHumanFormat(artistName)
                }
            });
        },

        getUserArtists(userId) {
            if (!userId) {
                console.warn('getUserArtists called without userId');
                return Promise.resolve([]);
            }

            const params = {owner: userId};
            return this.GET_USER_ARTISTS(params)
                .catch(error => {
                    console.error('Error getting user artists:', error);
                    return [];
                });
        },

        getRecentlyLikedAlbums(userId) {
            if (!userId) {
                console.warn('getRecentlyLikedAlbums called without userId');
                return Promise.resolve([]);
            }

            this.recentlyLikedAlbumsLoader = true;
            const params = {
                user: userId,
                status: 'published',
                limit: 6
            };

            return this.GET_USER_RECENTLY_LIKED_ALBUMS(params)
                .catch(error => {
                    console.error('Error getting liked albums:', error);
                    return [];
                })
                .finally(() => this.recentlyLikedAlbumsLoader = false);
        },

        follow(userId) {
            if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
                this.followBtnLoader = true;

                this.FOLLOW(userId)
                    .then(result => {
                        if (result === 'success') {
                            this.followBtnLoader = false;
                            this.isFollow = true;
                            this.PROFILE_USER.followersCount += 1;
                        } else {
                            this.errorDialog = true;
                        }
                    })
                    .catch(() => {
                        this.errorDialog = true;
                    })
                    .finally(() => this.followBtnLoader = false);
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },

        unfollow(userId) {
            if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
                this.followBtnLoader = true;

                this.UNFOLLOW(userId)
                    .then(result => {
                        if (result === 'success') {
                            this.followBtnLoader = false;
                            this.isFollow = false;
                            if (this.PROFILE_USER.followersCount >= 0) {
                                this.PROFILE_USER.followersCount -= 1;
                            }
                        } else {
                            this.errorDialog = true;
                        }
                    })
                    .catch(() => {
                        this.errorDialog = true;
                    })
                    .finally(() => this.followBtnLoader = false);
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },

        async copyUserLink() {
            const formattedName = window.toHumanFormat(this.PROFILE_USER.fullName);
            const shareUrl = `https://psychurch.org/user/${formattedName}`;
            
            try {
                // Try Web Share API first if available
                if (navigator.share) {
                    try {
                        await navigator.share({
                            title: this.PROFILE_USER.fullName,
                            url: shareUrl
                        });
                        return;
                    } catch (err) {
                        // Don't show error if user just cancelled the share
                        if (err.name === 'AbortError') {
                            return;
                        }
                        throw err; // Re-throw other errors to be caught below
                    }
                }
                
                // Fallback to clipboard API
                await this.copyToClipboard(shareUrl);
                this.copiedUserLink = true;
                setTimeout(() => {
                    this.copiedUserLink = false;
                }, 2000);
            } catch (err) {
                console.error('Share/Copy failed:', err);
                this.errorDialog = true;
            }
        },

        // Add new helper method for clipboard operations
        async copyToClipboard(text) {
            try {
                // Try modern clipboard API first
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(text);
                    return;
                }
                
                // Fallback to older execCommand method
                const textArea = document.createElement('textarea');
                textArea.value = text;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                
                const successful = document.execCommand('copy');
                textArea.remove();
                
                if (!successful) {
                    throw new Error('Failed to copy text');
                }
            } catch (err) {
                console.error('Copy failed:', err);
                throw err;
            }
        },

        updateAvatarData(user) {
            if (user.logo) {
                this.avatarData = {
                    isDefault: false,
                    smallUrl: user.logo.small,
                    srcset: user.logo.srcset,
                    original: user.logo.original,
                    key: new Date().getTime()
                };
            } else {
                this.resetAvatarData();
            }
        },
        resetAvatarData() {
            this.avatarData = {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            };
        },
        showAvatarModal() {
            const imageUrl = this.avatarData.original ? `${this.avatarData.original}?cache=${this.avatarData.key}` : 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/Default%20Profile%20Page%20Avatar.svg';
            this.$refs['modal-image'].image = imageUrl;
            this.$refs['modal-image'].show();
        },
        async updateBackgroundGradient(imageUrl) {
            try {
                const backgroundElement = this.$el.querySelector('.profile_head_background');
                if (backgroundElement) {
                    backgroundElement.classList.remove('visible');
                }

                const colors = await extractColorsFromImage(imageUrl);
                const newGradient = `linear-gradient(180deg, ${colors.topColor} 0%, ${colors.bottomColor} 100%)`;
                
                // Set the background first
                if (backgroundElement) {
                    backgroundElement.style.background = newGradient;
                }
                
                // Then update the data property
                this.backgroundGradient = newGradient;

                // Finally make it visible
                setTimeout(() => {
                    if (backgroundElement) {
                        backgroundElement.classList.add('visible');
                    }
                }, 50);
            } catch (error) {
                console.error('Error generating gradient:', error);
                this.backgroundGradient = null;
                this.setDefaultImage();
            }
        },
        setDefaultImage() {
            this.avatarData = {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            };
            this.backgroundGradient = null;

            const backgroundElement = this.$el.querySelector('.profile_head_background');
            if (backgroundElement) {
                backgroundElement.classList.remove('visible');
                backgroundElement.style.background = '';
                backgroundElement.classList.add('default');
            }
        }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
        const userId = to.params.id;
        
        store.dispatch('GET_PROFILE_USER', userId)
            .then(userData => {
                if (!userData?.id) {
                    next({ name: '404' });
                    return;
                }
                next(vm => {
                    vm.$nextTick(() => {
                        vm.getUserData();
                    });
                });
            })
            .catch(() => next({ name: '404' }));
    },

    beforeRouteUpdate(to, from, next) {
        const userId = to.params.id;
        
        this.loader = true;
        this.actionButtonsLoader = true;
        
        this.$store.dispatch('GET_PROFILE_USER', userId)
            .then(userData => {
                if (!userData?.id) {
                    throw new Error('Invalid user data');
                }
                next();
                this.$nextTick(() => {
                    this.getUserData();
                });
            })
            .catch(() => next({ name: '404' }));
    }
}
</script>

<style lang="scss">
.user-roles {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-top: 8px;

	.role-item {
		display: inline-flex;
		align-items: center;
		gap: 8px;
		padding: 8px 12px;
		background: rgba(26, 31, 52, 0.5);
		border: 1px solid rgba(116, 129, 166, 0.2);
		border-radius: 8px;
		color: rgba(255, 255, 255, 0.7);
		font-size: 14px;
		font-weight: 500;

		.icon {
			font-size: 16px;
		}
	}
}
</style>
