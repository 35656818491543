var render = function render(){var _vm=this,_c=_vm._self._c;return _c('exploreLayout',[_c('div',{staticClass:"explore"},[_c('div',{staticClass:"head_banner"},[_c('v-container',{staticClass:"full-height",attrs:{"full-height":""}},[(!_vm.subgenre)?_c('h3',[_vm._v("Explore Psychedelic Trance")]):_c('h3',[_vm._v(_vm._s(_vm.SUBGENRE.name))]),_c('nav',{ref:"exploreNav",staticClass:"explore_nav"},[_c('ul',{staticClass:"nav-list"},[_vm._l((_vm.visibleItems),function(item,index){return _c('li',{key:item.name || 'all',ref:"navItem",refInFor:true,class:{
				active:
				  item.name ? _vm.SUBGENRE.name === item.name : !_vm.SUBGENRE,
			  }},[_c('a',{on:{"click":function($event){return _vm.selectSubGenre(item.name ? item : {})}}},[_vm._v(" "+_vm._s(item.name || 'All')+" ")])])}),(_vm.hiddenItems.length)?_c('li',{staticClass:"more-dropdown"},[_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":"","content-class":"navy_blue dropdown-menu","close-on-click":true,"nudge-top":4,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"more-button"},'a',attrs,false),on),[_vm._v(" More ▾ ")])]}}],null,false,2957120726),model:{value:(_vm.isMoreDropdownOpen),callback:function ($$v) {_vm.isMoreDropdownOpen=$$v},expression:"isMoreDropdownOpen"}},[_c('v-list',_vm._l((_vm.hiddenItems),function(item){return _c('v-list-item',{key:item.name,staticClass:"dropdown-item",class:{ active: _vm.SUBGENRE.name === item.name },on:{"click":function($event){return _vm.selectSubGenre(item)}}},[_c('button',{staticClass:"dropdown-button"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),1)],1)],1):_vm._e(),_c('div',{staticClass:"animation_active_li",style:({
				left: _vm.activeSubgenrePosition + 'px',
				width: _vm.activeSubgenreWidth + 'px',
			  })})],2)])])],1),_c('div',{staticClass:"container full_page"},[(_vm.mobile)?[(_vm.AUTH_DATA.accessToken)?_c('albumsNavMobile'):_vm._e(),_c('albumsFilterMobile',{attrs:{"actions":false,"subgenreFilter":'dropdown',"keysFilter":true,"filterName":'explore'}}),(_vm.showAlbumsOrTracks === 'albums')?_c('albumsList',{attrs:{"whatAlbums":'all'}}):_vm._e(),(_vm.showAlbumsOrTracks === 'tracks')?_c('trackList'):_vm._e()]:[_c('albumsNav'),_c('albumsFilter',{attrs:{"actions":false,"subgenreFilter":'dropdown',"keysFilter":true,"filterName":'explore'}}),(_vm.showAlbumsOrTracks === 'albums')?_c('albumsList',{attrs:{"whatAlbums":'all'}}):_vm._e(),(_vm.showAlbumsOrTracks === 'tracks')?_c('trackList'):_vm._e(),_c('googleAdsense')]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }