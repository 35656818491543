<template>
    <v-dialog
        v-model="dialog"
        max-width="546"
        overlay-color="#0C1324"
        overlay-opacity="0.9"
        :content-class="'premium_feature_modal style4'">
        <div class="header_dialog">
            Premium Feature
        </div>
        <div class="cnt_dialog">
            This feature is available for premium members only. Upgrade your account to unlock all premium features and support this project.
        </div>
        <div class="wrap_actions_dialog center">
            <button type="button" class="tetriatary_btn" v-ripple @click="close">Maybe Later</button>
            <button type="button" class="c2a_btn" v-ripple @click="upgrade">
                Upgrade
                <span class="arrow">
                    <span class="line_svg"></span>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                        <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                    </svg>
                </span>
            </button>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'premiumFeatureDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        close() {
            this.dialog = false;
        },
        upgrade() {
            this.$router.push({name: 'memberships'});
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.premium_feature_modal {
    background: #282C47;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    padding: 25px 20px;

    .header_dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #fff;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(#5F6986, .3);
    }

    .cnt_dialog {
        padding: 20px 0;
        font-size: 16px;
        color: #dcdcdc;
        line-height: 24px;
        text-align: center;
    }

    .wrap_actions_dialog {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        border-top: 1px solid rgba(#5F6986, .3);

        button {
            width: 196px;
            height: 44px;
            font-size: 14px;
            color: #fff;
            border-radius: 120px;

            &:first-child {
                border: 1px solid #5F6986;
                margin-right: 10px;
                background: transparent;

                &:hover {
                    background: rgba(95, 105, 134, 0.1);
                }
            }

            &.c2a_btn {
                margin-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                background: linear-gradient(74.68deg, #FF0099 -12.21%, #20A8F5 112.21%);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                transition: 0.27s;

                &:hover {
                    background-color: #282C47;
                    .arrow {
                        .line_svg {
                            opacity: 1;
                            transform: translate(6px);
                            transition: all 0.2s;
                        }

                        svg {
                            transition: all 0.25s;
                            transform: translate(3px);
                        }
                    }
                }

                .arrow {
                    display: flex;
                    align-items: center;
                    margin-left: 5px;

                    .line_svg {
                        display: flex;
                        align-items: center;
                        width: 7px;
                        height: 2px;
                        background-color: #fff;
                        transform: translate(6px);
                        opacity: 0;
                        transition: all 0.2s;
                    }

                    svg {
                        transition: all 0.25s;
                    }
                }
            }
        }
    }
}
</style> 