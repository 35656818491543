<template>
    <lassoLayout class="page-login">
        <div class="page_form_layout full_page">
            <v-container>
                <div class="wrap_form_layout">
                    <div class="form-card">
                        <h2 class="login">Verify Email</h2>
                        <div class="verify-text">
                            We've sent a verification code to<br>
                            <span class='email-highlight'>{{tempUserEmail}}</span>
                        </div>

                        <div class="code" ref="codeWrapper" @paste="onPaste">
                            <div class="box_code_inputs">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_1" ref="digit_1"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(1)" @input="onInput($event, 1)">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_2" ref="digit_2"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(2)" @input="onInput($event, 2)">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_3" ref="digit_3"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(3)" @input="onInput($event, 3)">
                            </div>
                            <div class="separation">-</div>
                            <div class="box_code_inputs">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_4" ref="digit_4"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(4)" @input="onInput($event, 4)">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_5" ref="digit_5"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(5)" @input="onInput($event, 5)">
                                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_6" ref="digit_6"
                                       @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(6)" @input="onInput($event, 6)">
                            </div>
                        </div>

                        <div class="error-msg" v-if="SEND_VERIFY_CODE_STATUS.success === false">
                            {{ SEND_VERIFY_CODE_STATUS.message }}
                        </div>

                        <button v-if="code.digit_1 && code.digit_2 && code.digit_3 && code.digit_4 && code.digit_5 && code.digit_6" 
                                type="button" class="c2a_btn" v-ripple @click="sendCode" 
                                :class="{'loading': loader}" :disabled="loader">
                            Submit
                            <v-progress-circular :width="2" indeterminate></v-progress-circular>
                            <span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
                        </button>
                        <button v-else type="button" class="c2a_btn" disabled>Submit</button>

                        <div class="resend_code">
                            <div class="resend-title">Can't find the code?</div>
                            <template v-if="timer > 1">
                                <div class="resend-text">
                                    Send a new code
                                    <span class="in_seconds">(in {{ timer }}s)</span>
                                </div>
                            </template>
                            <template v-else>
                                <button type="button" v-ripple @click="resendCode">Send a new code</button>
                            </template>
                            <div class="or">
                                Or<a target="_blank" href="mailto:admin@psychurch.org">contact support</a>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import lassoLayout from "@/layouts/lassoLayout.vue";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'email-verification',
    components: {
        lassoLayout,
    },
    data() {
        return {
            loader: false,

            code: {
                digit_1: '',
                digit_2: '',
                digit_3: '',
                digit_4: '',
                digit_5: '',
                digit_6: '',
            },

            timer: 60,
            pastedText: '',
            arrayCode: [],
            codeValid: true,
            codeFocusEl: '',

            tempUserEmail: '',
            tempUserPassword: ''
        }
    },
    watch: {},
    // Validations
    validations: {
        code: {
            first: {
                required,
                maxLength: 1
            },
            two: {
                required,
                maxLength: 1
            },
            three: {
                required,
                maxLength: 1
            },
            four: {
                required,
                maxLength: 1
            },
            five: {
                required,
                maxLength: 1
            },
            six: {
                required,
                maxLength: 1
            }
        }
    },
    computed: {
        ...mapGetters(['AUTH_DATA', 'LOGIN_STATUS', 'SEND_VERIFY_CODE_STATUS'])
    },
    mounted() {
        this.tempUserEmail = localStorage.getItem('TEMP_USER_EMAIL');
        // If there is no data in storage, we don't know which user this is, so we redirect them to the login page.
        if (!this.tempUserEmail) {
          this.$router.push({name: 'login'});
        }

        this.$nextTick(() => {
            this.$refs.codeWrapper.focus();
            this.$refs.digit_1.focus();

            // Check if user came from login page
            let isMoveFromLogin = localStorage.getItem('IS_MOVE_FROM_LOGIN');
            if (isMoveFromLogin === 'true') {
                // Set timer to 0 to show resend link immediately
                this.timer = 0;
                localStorage.removeItem('IS_MOVE_FROM_LOGIN');
                return;
            }

            this.countDownTimer();
        });
    },
    methods: {
        ...mapActions({
            sendVerifyCode: 'SEND_VERIFY_CODE',
            resendVerifyCode: 'RESEND_VERIFY_CODE',
            autoLogin: 'LOGIN'
        }),
        onPaste(event) {
            event.preventDefault();
            const pastedText = event.clipboardData ? event.clipboardData.getData('text') : '';
            this.distributeCode(pastedText, 1);
        },
        onInput(event, digitIndex) {
            const inputValue = event.target.value;
            if (inputValue.length > 1) {
                event.preventDefault();
                this.distributeCode(inputValue, digitIndex);
            }
        },
        distributeCode(inputCode, startIndex) {
            const cleanCode = inputCode.replace(/\D/g, '').slice(0, 6);
            for (let i = 0; i < cleanCode.length; i++) {
                const digitIndex = startIndex + i;
                if (digitIndex <= 6) {
                    this.code[`digit_${digitIndex}`] = cleanCode[i];
                }
            }
            const focusIndex = Math.min(startIndex + cleanCode.length, 6);
            this.$nextTick(() => {
                this.$refs[`digit_${focusIndex}`].focus();
            });
        },
        keyup(event) {
            let activeFocus;
            if (((event.keyCode !== 17 || event.keyCode !== 91) && event.keyCode !== 88) && event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
            }
            if (activeFocus) {
                this.$nextTick(() => {
                    activeFocus.focus();
                })
            }
        },
        codeFocus(el) {
            this.codeFocusEl = el;
        },
        keypress(event) {
            if (event.key === 'v') {
                return;
            }

            // only allow numbers
            if ((event.keyCode < 48 || event.keyCode > 57)) {
                event.preventDefault();
            } else {
                this.code[`digit_${this.codeFocusEl}`] = event.key;
            }
        },
        keydown(event) {
            let activeFocus;

            switch (event.key) {
                case 'ArrowLeft':
                    activeFocus = this.$refs[`digit_${this.codeFocusEl - 1}`];
                    break;
                case 'ArrowRight':
                    activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
                    break;
                case 'Backspace':
                    event.preventDefault();
                    this.code[`digit_${+this.codeFocusEl}`] = '';
                    activeFocus = this.$refs[`digit_${this.codeFocusEl - 1}`];
                    break;
                case 'Delete':
                    event.preventDefault();
                    this.code[`digit_${+this.codeFocusEl}`] = '';
                    activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
                    break;
            }
            if (activeFocus) {
                this.$nextTick(() => {
                    activeFocus.focus();
                })
            }
        },
        sendCode() {
            let finalCode = `${this.code.digit_1}${this.code.digit_2}${this.code.digit_3}${this.code.digit_4}${this.code.digit_5}${this.code.digit_6}`;

            if (finalCode.length === 6 && this.tempUserEmail) {
                this.loader = true;
                const params = {
                    email: this.tempUserEmail,
                    code: finalCode
                }
                this.sendVerifyCode(params)
                    .then(() => {
                        if (this.SEND_VERIFY_CODE_STATUS.success === true && this.SEND_VERIFY_CODE_STATUS.tokens) {
                            localStorage.removeItem('TEMP_USER_EMAIL');
                            this.$store.commit('SAVE_TOKENS', this.SEND_VERIFY_CODE_STATUS.tokens);
                            this.$router.push({name: 'profile-basics'})
                                .catch(() => {
                                    this.loader = false;
                                });
                        } else {
                            this.$store.commit('SET_SEND_VERIFY_CODE_STATUS', {success: false, message: 'Invalid code'});
                            this.loader = false;
                        }
                    })
                    .catch(err => {
                        console.error(`sendCode ${err}`);
                        this.loader = false;
                    });
            } else {
                this.$router.push({name: 'login'});
            }
        },
        resendCode() {
            this.timer = 30;
            if (this.tempUserEmail) {
                this.resendVerifyCode({email: this.tempUserEmail}).then(() => {
                    this.$store.commit('SET_SEND_VERIFY_CODE_STATUS', {});
                    this.countDownTimer();
                });
            } else {
                console.log('resendCode redirect to login');
                this.$router.push({name: 'login'});
            }
        },
        countDownTimer() {
            if (this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/pages/_login.scss';

.page-login {
    .verify-text {
        text-align: center;
        color: $label-color;
        margin: 24px 0 32px;
        font-size: 15px;
        line-height: 1.6;

        .email-highlight {
            color: white;
            font-weight: 500;
            display: block;
            margin-top: 8px;
            font-size: 16px;
        }
    }

    .code {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin: 0 0 32px;

        .box_code_inputs {
            display: flex;
            gap: 8px;
        }

        input {
            width: 48px;
            height: 56px;
            background: $input-bg;
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 4px;
            color: white;
            text-align: center;
            font-size: 20px;
            @include input-transition;

            &:focus {
                outline: none;
                border-color: rgba(135, 206, 250, 0.7);
            }
        }

        .separation {
            color: $label-color;
            font-size: 20px;
            user-select: none;
        }
    }

    .resend_code {
        text-align: center;
        margin: 24px 0 0;
        color: $label-color;
        font-size: 14px;
        line-height: 1.5;

        .resend-title {
            margin-bottom: 8px;
            font-size: 15px;
        }

        .resend-text {
            color: rgba(255, 255, 255, 0.6);
        }

        button {
            color: #20C4F5;
            background: none;
            border: none;
            padding: 0;
            margin: 0;
            font-size: inherit;
            cursor: pointer;
            @include input-transition;

            &:hover {
                opacity: $hover-opacity;
            }
        }

        .in_seconds {
            color: rgba(255, 255, 255, 0.4);
            margin-left: 4px;
            font-size: 13px;
        }

        .or {
            margin-top: 12px;
            background: none;
            font-size: 14px;
            color: $label-color;
            
            &:before, &:after {
                display: none;
                content: none;
            }
            
            a {
                margin-left: 4px;
                color: #20C4F5;
                text-decoration: none;
                position: relative;
                padding-bottom: 2px;
                @include input-transition;
                
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: currentColor;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.3s ease;
                }
                
                &:hover:after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }
    }
}
</style>
