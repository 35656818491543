import axios from 'axios';

const initialState = () => ({
    profileUser: {},
    userUploadedAlbums: [],
    userRecentlyLikedAlbums: [],
    profileUserLogo: {},
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState);
        },
        SET_PROFILE_USER: (state, data) => {
            const cleanData = JSON.parse(JSON.stringify(data));
            state.profileUser = cleanData;
            state.profileUserLogo = {};

            if (cleanData?.photo?.thumbnail) {
                state.profileUserLogo = {...cleanData.photo.thumbnail};
            }
        },
        SET_USER_UPLOADED_ALBUMS: (state, data) => {
            state.userUploadedAlbums = data;
        },
        SET_USER_RECENTLY_LIKED_ALBUMS: (state, data) => {
            state.userRecentlyLikedAlbums = data;
        },
    },
    actions: {
        GET_PROFILE_USER({commit}, userID) {
            return axios.get(`users/${userID}`)
                .then(response => {
                    if (!response?.data) {
                        throw new Error('No data in response');
                    }

                    const userData = Array.isArray(response.data) ? response.data[0] : response.data;
                    
                    if (!userData || typeof userData !== 'object') {
                        throw new Error('Invalid user data format');
                    }

                    if (!userData.id) {
                        throw new Error('User data missing ID');
                    }

                    const cleanUserData = JSON.parse(JSON.stringify(userData));
                    commit('SET_PROFILE_USER', cleanUserData);
                    return cleanUserData;
                })
                .catch(err => {
                    commit('SET_PROFILE_USER', {});
                    throw err;
                });
        },
        GET_USER_UPLOADED_ALBUMS({commit}, params) {
            commit('SET_USER_UPLOADED_ALBUMS', []);
            return axios.get('music_releases', {params: params})
                .then(response => {
                    commit('SET_USER_UPLOADED_ALBUMS', response.data['hydra:member']);
                    return response.data['hydra:member'];
                })
                .catch(err => {
                    console.error('Error getting uploaded albums:', err);
                    throw err;
                });
        },
        GET_USER_RECENTLY_LIKED_ALBUMS({commit}, params) {
            commit('SET_USER_RECENTLY_LIKED_ALBUMS', []);
            return axios.get(`public_favorite_song/${params.user}`, {params: params})
                .then(response => {
                    commit('SET_USER_RECENTLY_LIKED_ALBUMS', response.data.items);
                    return response.data.items;
                })
                .catch(err => {
                    console.error('Error getting liked albums:', err);
                    throw err;
                });
        },
    },

    getters: {
        PROFILE_USER: state => state.profileUser,
        PROFILE_USER_LOGO: state => state.profileUserLogo,
        USER_UPLOADED_ALBUMS: state => state.userUploadedAlbums,
        USER_RECENTLY_LIKED_ALBUMS: state => state.userRecentlyLikedAlbums,
    }
}
