<template>
    <supportLayout>
        <div class="p-faq">

            <v-container>
                <section class="about">
                    <h3 class="title">About</h3>
                    <div class="description">
                        Welcome to our online platform for psychedelic trance, music and events.
                        <br>
                        Like what you see? <router-link class="hover-link" to="/memberships">Sign up for a premium plan</router-link> to support further development or donate via <a class="hover-link" :href="PATREON" target="_blank">Patreon</a>.
                        <br>
                        For updates follow on <a class="hover-link" :href="FACEBOOK" target="_blank">Facebook</a>
                    </div>
                </section>
            </v-container>


            <!-- ADMIN CONTENT -->
            <template v-if="userRole === 'ADMIN'">
                <section class="faq-section faq-admin">
                    <div class="container">
                        <div class="wrap_create_faq">
                            <div class="create_faq">
                                <h3 class="title">Create new FAQ</h3>

                                <v-progress-circular v-if="createLoader"
                                                     :size="100"
                                                     :width="3"
                                                     color="#20C4F5"
                                                     indeterminate
                                ></v-progress-circular>

                                <input type="text" autofocus
                                       :class="[{invalid: $v.question.$invalid && submitted}, 'input_style']"
                                       v-model="question"
                                       placeholder="Add a question"/>

                                <textarea-autosize :class="{'invalid': $v.answer.$invalid && submitted}"
                                                   placeholder="Add an answer"
                                                   v-model="answer"
                                                   :min-height="110"
                                                   :max-height="510"/>

                                <button type="button" @click="createFaq()" :disabled="$v.question.$invalid || $v.answer.$invalid">Post</button>
                            </div>
                        </div>

                        <h3 class="title" v-if="FAQS.length">Change existing FAQ</h3>

                        <div class="wrap_questions">
                            <draggable :list="FAQS" class="questions admin-questions" handle=".handle" v-bind="dragOptions" @change="update(FAQS, $event)">
                                <div class="admin-question-item" v-for="faq of FAQS" :key="faq.id + dynamicFaqKey" :class="{active: faq.show === true}">
                                    <div class="question admin-question">
                                        <div class="handle"></div>
                                        <div class="visible" :class="{'show': faq.show === true}" @click="openQuestion(faq)"></div>
                                        <input type="text" v-model="faq.question" :disabled="userRole !== 'ADMIN'"/>
                                        <span class="saved" v-if="itemSaveFaq.status && itemSaveFaq.id === faq.id">Saved!</span>
                                        <button v-if="!itemSaveFaq.status || itemSaveFaq.id !== faq.id && userRole === 'ADMIN'" class="check"
                                                @click="saveFaq(faq)">
                                        </button>
                                        <button v-if="!itemSaveFaq.status || itemSaveFaq.id !== faq.id && userRole === 'ADMIN'" class="faq_delete"
                                                @click="deleteFaq(faq.id)">
                                            <span class="material-icons">delete</span>
                                        </button>
                                    </div>
                                    <textarea-autosize
                                            v-show="faq.show === true"
                                            class="answer admin-answer"
                                            :disabled="userRole !== 'ADMIN'"
                                            v-model="faq.answer"
                                            ref="contentTextArea"
                                            :min-height="30"/>
                                </div>
                            </draggable>
                        </div>

                    </div>


                    <v-dialog
                            v-if="deleteFaqDialog"
                            v-model="deleteFaqDialog"
                            max-width="490">
                        <div class="delete_head_promt">
                            You are about to remove a question <br>Confirm to proceed
                        </div>
                        <div class="action_delete_faq">
                            <button class="cancel" @click="deleteFaqDialog = false" v-ripple>Cancel</button>
                            <button class="delete_faq" @click="confirmDeleteFaq(deleteItemFaq)" v-ripple>Delete</button>
                        </div>
                    </v-dialog>

                </section>

            </template>

            <!-- USER CONTENT -->
            <template v-if="userRole !== 'ADMIN'">
                <section class="faq-section faq-user">
                    <div class="container">
                        <div class="wrap_questions">
                            <div class="questions user-questions">
                                <div class="title_">Frequently Asked Questions</div>
                                <template v-if="loader">
                                    <v-skeleton-loader
                                            type="image"
                                            class="item_track"
                                            v-for="index in 10"
                                            :key="index"
                                    ></v-skeleton-loader>
                                </template>
                                <template v-if="!loader">
                                    <div class="user-question-item" v-for="faq of FAQS" :key="faq.id + dynamicFaqKey">
                                        <div class="question user-question" @click="openQuestion(faq)">
                                            <span class="question-text">{{ faq.question }}</span>
                                        </div>
                                        <div class="answer user-answer" v-if="faq.show === true" :class="{'show': faq.show === true}">{{ faq.answer }}</div>
                                    </div>
                                </template>
                            </div>
                            <div class="discord">
                                <h4>More questions? Lets chat!</h4>
                                <iframe src="https://discord.com/widget?id=1074682103767236639&theme=dark" width="350" height="350" allowtransparency="true"
                                        frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </template>


        </div>
    </supportLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {required, minLength} from 'vuelidate/lib/validators';
import supportLayout from "@/layouts/supportLayout.vue";

import draggable from 'vuedraggable';
import isMobile from "@/mixins/isMobile";

export default {
    name: 'faq',
    components: {
        supportLayout,
        draggable
    },
    mixins: [isMobile],
    data() {
        return {
            createLoader: false,
            loader: true,

            // create faq
            question: '',
            answer: '',
            status: 'active',

            itemFaqOpen: Number,
            dragging: false,

            submitted: false,
            itemSaveFaq: {
                id: null,
                status: false,
            },
            userRole: '',
            deleteFaqDialog: false,
            deleteItemFaq: Object,
            dynamicFaqKey: 1,
        };
    },
    validations: {
        question: {
            required,
            minLength: minLength(2)
        },
        answer: {
            required,
            minLength: minLength(2)
        }
    },

    computed: {
        ...mapGetters(['FAQS', 'AUTH_DATA', 'FACEBOOK', 'PATREON']),

        dragOptions() {
            return {
                animation: 0,
                disabled: false,
            };
        }
    },

    mounted() {
        this.initFaqs();
        if (this.AUTH_DATA.userRoles) {
            if (this.AUTH_DATA.userRoles.find(user => user === 'ROLE_ADMIN')) {
                this.userRole = 'ADMIN';
            }
        }
    },

    methods: {
        ...mapActions(['GET_FAQS', 'ADD_FAQ', 'UPDATE_FAQ', 'DELETE_FAQ']),
        openQuestion(itemFaq) {
            itemFaq.show = !itemFaq.show;
            this.dynamicFaqKey += 1;
        },

        update: function (faqList, $event) {
            // Update positions for all items in the list
            const updates = faqList.map((faq, index) => ({
                id: faq.id,
                question: faq.question,
                answer: faq.answer,
                position: index
            }));

            this.loader = true;
            
            // Update each FAQ item with its new position
            Promise.all(updates.map(update => this.UPDATE_FAQ(update)))
                .then(() => {
                    this.loader = false;
                    // Refresh the list to ensure correct order
                    this.initFaqs();
                })
                .catch(error => {
                    console.error('Error updating positions:', error);
                    this.loader = false;
                });
        },

        initFaqs() {
            this.GET_FAQS().then(() => {
                this.FAQS.sort((a, b) => a.position - b.position);
                this.loader = false;
            });
        },

        createFaq() {
            this.createLoader = true;
            this.submitted = true;

            if (this.userRole !== 'ADMIN') {
                this.status = 'inactive';
            }
            let createFaq = {
                question: String(this.question),
                answer: String(this.answer),
                status: String(this.status),
                position: Number(this.FAQS.length + 1),
            };
            this.ADD_FAQ(createFaq)
                .then(() => {
                    this.submitted = false;
                    this.question = '';
                    this.answer = '';
                    this.initFaqs();
                })
                .catch((err) => {
                    console.log(`createFaq ${err}`);
                })
                .finally(() => {
                    this.createLoader = false;
                })
        },

        saveFaq(faq) {
            this.loader = true;
            this.UPDATE_FAQ(faq).then(() => {
                this.loader = false;
                this.itemSaveFaq.status = true;
                this.itemSaveFaq.id = faq.id;
                setTimeout(() => this.itemSaveFaq.status = false, 1800);
            });
        },

        deleteFaq(id) {
            this.deleteFaqDialog = true;
            this.deleteItemFaq = id;
        },
        confirmDeleteFaq() {
            if (this.deleteItemFaq) {
                this.DELETE_FAQ(this.deleteItemFaq).then(() => {
                    this.loader = false;
                    this.initFaqs();
                })
                this.deleteFaqDialog = false;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/styles/pages/_faq";
</style>

