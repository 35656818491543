<template>
    <footer>
      <v-container>
        <div class="left_box">
          <div class="footer_logo"></div>
          <span>Copyright © 2021</span>
        </div>
        <div class="right_box">
          <div class="links">
            <router-link :to="{name: 'faq'}">About</router-link>
            <router-link :to="{name: 'changelog'}">Changelog</router-link>
            <router-link :to="{name: 'feature-suggestions'}">Suggest a feature</router-link>
            <a href="mailto:info@psychurch.org?subject=Found a bug!">Report a bug</a>
            <a href="/FunkyTOS.html" target="_blank">Terms of Service</a>
            <a href="#" @click.prevent="showContactDialog = true">Contact us</a>
          </div>
          <div class="social_block">
            <a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="facebook"></a>
          </div>
        </div>
      </v-container>
      <contact-dialog v-model="showContactDialog" />
    </footer>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import ContactDialog from '../dialogs/contactDialog.vue';
  
  export default {
    name: 'mainFooter',
    components: {
      ContactDialog
    },
    data() {
      return {
        showContactDialog: false
      }
    },
    computed: {
      ...mapGetters(['FACEBOOK'])
    }
  }
  </script>
  
  <style scoped>
  /* You can add or update your footer styles here */
  </style>
  