<template>
  <div 
    class="swipe-handler"
    :class="direction"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd">
    <div 
      class="swipe-visual" 
      :style="visualStyle"
      :class="{ 'active': isActive }">
      <div class="fabric-top"></div>
      <div class="fabric-middle"></div>
      <div class="fabric-bottom"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';

export default {
  name: 'SwipeNavigation',
  props: {
    direction: {
      type: String,
      required: true,
      validator: value => ['left', 'right'].includes(value)
    }
  },
  data() {
    return {
      touchStartX: 0,
      touchEndX: 0,
      touchY: 0,
      minSwipeDistance: 100,
      isIOS: false,
      isActive: false,
      currentSwipeDistance: 0
    }
  },
  computed: {
    visualStyle() {
      if (!this.isActive) return {};
      
      const width = Math.min(Math.abs(this.currentSwipeDistance), 150);
      const progress = width / 150;
      const opacity = Math.min(progress * 1.5, 0.95);
      const touchPercentage = (this.touchY / window.innerHeight) * 100;
      
      const bubbleScale = 1 + Math.sin(progress * Math.PI) * 0.8;
      const verticalStretch = 1 + (1 - progress) * 0.5;
      
      return {
        '--pull-width': `${width}px`,
        '--touch-y': `${touchPercentage}%`,
        '--bubble-scale': bubbleScale,
        '--vertical-stretch': verticalStretch,
        opacity: opacity,
        '--hue': Math.floor(progress * 60)
      };
    }
  },
  mounted() {
    this.isIOS = CSS.supports('-webkit-touch-callout', 'none') && Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
  },
  methods: {
    async triggerHapticFeedback() {
      if (!this.isIOS) return;
      
      try {
        await Haptics.impact({ style: 'light' });
      } catch (error) {
        console.log('Haptic feedback failed:', error);
      }
    },
    handleTouchStart(e) {
      if (!this.isIOS) return;
      this.touchStartX = e.touches[0].clientX;
      this.touchY = e.touches[0].clientY;
      this.isActive = true;
      this.currentSwipeDistance = 0;
    },
    handleTouchMove(e) {
      if (!this.isIOS) return;
      this.touchEndX = e.touches[0].clientX;
      this.touchY = e.touches[0].clientY;
      this.currentSwipeDistance = this.touchEndX - this.touchStartX;
      
      if (this.direction === 'left' && this.currentSwipeDistance < 0) {
        this.isActive = false;
      } else if (this.direction === 'right' && this.currentSwipeDistance > 0) {
        this.isActive = false;
      }
    },
    async handleTouchEnd() {
      if (!this.isIOS) return;
      const swipeDistance = this.touchEndX - this.touchStartX;
      
      if (Math.abs(swipeDistance) > this.minSwipeDistance) {
        if (this.direction === 'left' && swipeDistance > 0) {
          if (this.$router.currentRoute.name !== 'home') {
            await this.triggerHapticFeedback();
            this.$router.back();
          }
        } else if (this.direction === 'right' && swipeDistance < 0) {
          if (window.history.length > 1) {
            await this.triggerHapticFeedback();
            this.$router.forward();
          }
        }
      }
      
      this.isActive = false;
      this.currentSwipeDistance = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.swipe-handler {
  position: fixed;
  top: env(safe-area-inset-top);
  bottom: 0;
  width: 20px;
  z-index: 1000;

  &.left {
    left: 0;
    .swipe-visual {
      left: 0;
      .fabric-middle {
        background: radial-gradient(
          circle at left,
          rgba(88, 101, 242, 0.7),
          rgba(88, 101, 242, 0.3) 50%,
          transparent 100%
        );
        transform-origin: left center;
        filter: blur(3px);
      }
    }
  }

  &.right {
    right: 0;
    .swipe-visual {
      right: 0;
      .fabric-middle {
        background: radial-gradient(
          circle at right,
          rgba(88, 101, 242, 0.7),
          rgba(88, 101, 242, 0.3) 50%,
          transparent 100%
        );
        transform-origin: right center;
        filter: blur(3px);
      }
    }
  }
}

.swipe-visual {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: opacity 0.15s ease;
  pointer-events: none;
  will-change: transform, opacity;
  
  &.active {
    transition: none;
    width: var(--pull-width);
  }

  .fabric-middle {
    position: absolute;
    width: 150%;
    height: 150px;
    top: calc(var(--touch-y) - 75px);
    transition: transform 0.1s ease-out;
    will-change: transform;
    transform: scale(var(--bubble-scale)) scaleY(var(--vertical-stretch));
    border-radius: 50%;
    mix-blend-mode: screen;
  }

  // Remove top and bottom sections as we're using a single bubble
  .fabric-top, .fabric-bottom {
    display: none;
  }
}
</style> 