<template>
    <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card class="account-exists-dialog">
            <v-card-title>Account Already Exists</v-card-title>
            <v-card-text>
                An account with this email already exists. Would you like to log in with these credentials?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel" :disabled="loading" class="cancel-btn">Cancel</v-btn>
                <v-btn color="primary" @click="login" :disabled="loading" class="login-btn">
                    <template v-if="loading">
                        <v-progress-circular size="20" width="2" indeterminate color="white"></v-progress-circular>
                    </template>
                    <template v-else>
                        Log In
                    </template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "accountExistsDialog",
    props: {
        value: Boolean,
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');
            this.dialog = false;
        },
        login() {
            this.$emit('login');
        }
    }
}
</script>

<style lang="scss">
.account-exists-dialog {
    background: #040A18 !important;
    border: 1px solid #282C47;
    border-radius: 5px;

    .v-card__title {
        color: #dcdcdc;
        font-size: 18px;
        font-weight: 500;
        padding: 16px;
    }

    .v-card__text {
        color: #7481A6;
        font-size: 14px;
        padding: 0 16px 16px;
    }

    .v-card__actions {
        padding: 16px;
        border-top: 1px solid #282C47;

        .cancel-btn {
            color: #20C4F5 !important;
            text-transform: none;
            letter-spacing: normal;
            font-weight: normal;
        }

        .login-btn {
            background: #20C4F5 !important;
            color: white !important;
            text-transform: none;
            letter-spacing: normal;
            font-weight: normal;
            min-width: 80px;
            height: 36px;
            position: relative;

            .v-progress-circular {
                margin: 0 !important;
            }
        }
    }
}
</style> 