<template>
	<artistProfileLayout>
		<div class="artist_profile" data-page="artist-profile">
			<div class="artist_profile_head">
				<div 
					class="profile_head_background"
					:class="{
						'visible': !!backgroundGradient,
						'animating': true
					}"
					:style="backgroundGradient ? {
						background: backgroundGradient
					} : null">
				</div>
				<div 
					class="profile_head_background default"
					:class="{
						'hidden': !!backgroundGradient
					}">
				</div>
				<v-container>
					<div class="artist_data">
						<artistProfileAvatar 
							ref="artistProfileAvatar"
							:isAdmin="ARTIST.owner === true" 
							:artistID="id" 
							:isLoading="loader" 
							@avatar-updated="handleImageUpdated">
						</artistProfileAvatar>

						<div class="artist_info">
							<div class="mobile_artist_name" v-if="mobile">
								<template v-if="loader">
									<div class="skeleton-loader artist-name-skeleton"></div>
								</template>
								<template v-else>
									<h2 class="artist_name">{{ artist.name }}</h2>

									<!-- more button -->
									<v-menu
										transition="slide-y-transition"
										:close-on-content-click="true"
										offset-y
										nudge-left="21"
										nudge-bottom="10"
										origin="top center"
										content-class="menu-pointer charcoal">
										<template v-slot:activator="{ on, attrs }">
											<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
												<span class="material-icons">more_vert</span>
											</v-btn>
										</template>
										<v-list>
											<!-- popup share link -->
											<v-list-item>
												<v-dialog transition="dialog-bottom-transition" max-width="600">
													<template v-slot:activator="{ on, attrs }">
														<button v-ripple type="button" v-bind="attrs" v-on="on">Share</button>
													</template>
													<template v-slot:default="dialog">
														<div class="content_popup_share">
															<div class="head_popup">Share</div>
															<div class="input_box">
																<input type="text" id="input-share-link" v-model="shareLink">
																<button v-ripple type="button" @click="copyArtistLink">Copy</button>
															</div>
															<button v-ripple type="button" @click="dialog.value = false" class="close">Close</button>
														</div>
													</template>
												</v-dialog>
											</v-list-item>
											<!-- Report -->
											<v-list-item v-ripple>
												<button type="button" class="report">Report</button>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
							</div>

							<template v-if="!mobile">
								<template v-if="loader">
									<div class="skeleton-loader artist-name-skeleton"></div>
								</template>
								<template v-else>
									<h2 class="artist_name">
										{{ artist.name }}
										<button type="button" v-if="ARTIST.owner === false" @click="dialogAreYouThisArtistVisible = true" class="is-this-you-link">
											(Is this you?)
										</button>
									</h2>
								</template>
							</template>

							<div class="follows" v-if="ARTIST || loader">
								<template v-if="loader">
									<div class="followers">
										<div class="skeleton-loader followers-skeleton"></div>
									</div>
								</template>
								<template v-else>
									<div class="followers">
										<span class="count">{{ followersCount }}</span>
										<div>Followers</div>
									</div>
								</template>
							</div>

							<are-you-this-artist-dialog v-if="dialogAreYouThisArtistVisible" :artist="ARTIST" v-model="dialogAreYouThisArtistVisible">
							</are-you-this-artist-dialog>

						</div>
					</div>
				</v-container>
			</div>

			<div class="wrap_profile_music_events">
				<div class="head_profile_music_events">
					<v-container>
						<div class="head_content">
							<v-tabs
								background-color="transparent"
								color="#fff"
								v-model="tabs">
								<v-tab v-for="tab in tabsSection" :key="tab.index">
									{{ tab.name }}
								</v-tab>
							</v-tabs>


							<div class="actions">
								<!-- Owner actions -->
								<template v-if="ARTIST.owner === true">
									<button type="button" class="edit_profile" v-if="viewContent !== 'edit-profile'" @click="editArtistProfile" v-ripple>
										Edit profile
									</button>
								</template>

								<!-- Non-owner actions -->
								<template v-else-if="ARTIST && ARTIST.owner === false">
									<!-- Initial loading state -->
									<template v-if="loader || actionButtonsLoader">
										<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-1'"></div>
										<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-2'"></div>
										<div class="skeleton-loader action-button-skeleton" :key="'action-skeleton-3'"></div>
									</template>

									<!-- Loaded state -->
									<template v-else>
										<!-- Follow Button -->
										<template v-if="AUTH_DATA.accessToken.length">
											<template v-if="followBtnLoader">
												<div class="skeleton-loader action-button-skeleton"></div>
											</template>
											<template v-else>
												<button type="button" class="follow_btn" v-if="!isFollow" @click="follow(ARTIST.id)">
													<span class="icon"></span>
													Follow
												</button>
												<button type="button" class="follow_btn following" v-else
														@click="unfollow(ARTIST.id)"
														@touchend="hoverFollowingButton = false"
														@mouseover="hoverFollowingButton = true"
														@mouseleave="hoverFollowingButton = false">
													<span class="icon"></span>
													<template v-if="hoverFollowingButton === true">Unfollow</template>
													<template v-else>Following</template>
												</button>
											</template>
										</template>
										<template v-else>
											<button type="button" class="follow_btn" @click="dialogNeedLoginVisible = true">
												<span class="icon"></span>
												Follow
											</button>
										</template>

										<!-- Message Button -->
										<button type="button" class="message_btn" 
												@click="AUTH_DATA.accessToken.length ? showComingSoonDialog = true : dialogNeedLoginVisible = true">
											<span class="icon"></span>
											Message
										</button>

										<!-- Share Button -->
										<button v-if="!copiedArtistLink" type="button" class="share_btn" @click="copyArtistLink">
											<span class="icon"></span>
											Share
										</button>
										<button v-else type="button" class="share_btn">
											Copied!
										</button>
									</template>
								</template>

								<!-- Loading state when owner is undefined -->
								<template v-else-if="ARTIST && typeof ARTIST.owner === 'undefined'">
									<div class="skeleton-loader action-button-skeleton" :key="'loading-skeleton-1'"></div>
									<div class="skeleton-loader action-button-skeleton" :key="'loading-skeleton-2'"></div>
									<div class="skeleton-loader action-button-skeleton" :key="'loading-skeleton-3'"></div>
								</template>

								<!-- More button menu -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<!-- popup share link -->
										<v-list-item v-ripple>
											<button type="button" class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</v-container>
				</div>
				<div class="content_profile_music_events">
					<v-tabs-items touchless v-model="tabs">

						<!-- Music Tab -->
						<v-tab-item :transition="false">
							<div class="music_section artist_releases">

								<div class="uploaded_music"
								     v-if="(releasesAlbumsLoader && !ARTIST_PROFILE_RELEASES_ALBUMS.length) || (!releasesAlbumsLoader && ARTIST_PROFILE_RELEASES_ALBUMS.length)">
									<v-container>
										<div v-if="releasesAlbumsLoader" class="wrap_music">
											<albumGridSkeletonLoader 
												v-for="index in 6" 
												:key="index" 
												:parent="'default_grid_albums'">
											</albumGridSkeletonLoader>
										</div>

										<div class="wrap_music group" v-if="!releasesAlbumsLoader && ARTIST_PROFILE_RELEASES_ALBUMS.length">
											<template v-for="(group, groupIndex) of groupArtistAlbums">
												<div class="item" v-for="(item, itemIndex) in group" :key="`group-${groupIndex}-item-${itemIndex}`">
													<div class="wrap_year">
														<span class="year" v-if="itemIndex === 0">{{ String(Object.keys(group[0])) }}</span>
														<span class="line"></span>
													</div>
													<albumGrid
														v-for="(album, albumIndex) of item"
														:key="`album-${album.id}-${albumIndex}`"
														:hide-artist="true"
														:album="album"
														:playZone="ARTIST_PROFILE_RELEASES_ALBUMS">
													</albumGrid>
												</div>
											</template>
										</div>

									</v-container>
								</div>

								<div class="appears_on" v-if="appearsAlbums.length">
									<div class="container">
										<div class="content_header">
											<h3 class="_head_name">Appears on</h3>
										</div>
										<div class="wrap_music group">
											<template v-for="(group, groupIndex) of groupAppearsAlbums">
												<div class="item" v-for="(item, itemIndex) in group" :key="`appears-group-${groupIndex}-item-${itemIndex}`">
													<div class="wrap_year">
														<span class="year" v-if="itemIndex === 0">{{ String(Object.keys(group[0])) }}</span>
														<span class="line"></span>
													</div>
													<albumGrid
														v-for="(album, albumIndex) of item"
														:key="`appears-album-${album.id}-${albumIndex}`"
														:album="album"
														:hide-artist="true"
														:playZone="appearsAlbums">
													</albumGrid>
												</div>
											</template>
										</div>
									</div>
								</div>

								<template v-if="(!ARTIST_PROFILE_RELEASES_ALBUMS.length && !releasesAlbumsLoader) && !appearsAlbums.length">
									<nothingHereYet></nothingHereYet>
								</template>

							</div>
						</v-tab-item>

						<!-- Events Tab -->
						<v-tab-item :transition="false">
							<div class="events_section">
								<div class="uploaded_events">
									<v-container>
										<div class="content_header">
                                            <span class="_head_name">
                                                Coming up
                                            </span>
										</div>
										<nothingHereYet></nothingHereYet>

									</v-container>
								</div>
							</div>
						</v-tab-item>

						<!-- About Tab -->
						<v-tab-item :transition="false">
							<div class="artist_about">
								<v-container class="v_container_info">
									<div class="content_header">
                                    <span class="_head_name">
                                       Info
                                    </span>
									</div>
									<div class="wrap_about_section">
										<div class="artist_about_info" v-if="ARTIST_OWNER">
											Author
											<span @click="redirectToUserProfile(ARTIST_OWNER.id, ARTIST_OWNER.identifier)">
												{{ ARTIST_OWNER.fullName }}
											</span>
										</div>
									</div>
								</v-container>
								<v-container class="v_container_bio">
									<div class="content_header">
                                    <span class="_head_name">
                                       Bio
                                    </span>
									</div>
									<div class="wrap_about_section">
										<template v-if="viewContent === 'edit-profile'">
											<TextareaAutosize v-model="artist.description"></TextareaAutosize>
											<div class="wrap_action_btn">
												<button type="button" class="save" v-if="UNIQUE_ARTIST_NAME === true" v-ripple @click="changeArtistData" :class="{loading: changeArtistLoader}">
													<v-progress-circular :width="2" indeterminate></v-progress-circular>
													Save
												</button>
												<div v-else class="secondary_btn disabled button">Save</div>

												<button type="button" class="close" v-ripple @click="cancel">Cancel</button>
											</div>
										</template>

										<template v-else>
											<template v-if="artist.description">
												<!--                                                <div v-html="formattedBio"></div>-->
												<div v-html="artist.description.replace(/\n/g, '<br>')"></div>
											</template>
											<template v-else>
												<nothingHereYet></nothingHereYet>
											</template>
										</template>
									</div>
								</v-container>

							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>


			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
			<dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible"></dialogNeedLogin>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
		</div>
		<PhotoClipper
			v-if="showPhotoClipper"
			:show="showPhotoClipper"
			:image="selectedImage"
			@close="showPhotoClipper = false"
			@save="handleSaveImage"
			@image-updated="handleImageUpdated"
			@update-cache-key="updateCacheKey"
		/>
	</artistProfileLayout>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import moment from 'moment';
import artistProfileLayout from "@/layouts/profiles/artistProfileLayout.vue";

import artistProfileAvatar from "@/components/profile/artistProfileAvatar";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";

import albumGrid from '@/components/musicReleases/albumGrid';

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
const areYouThisArtistDialog = () => import('@/components/dialogs/areYouThisArtist');
const nothingHereYet = () => import("@/components/small/nothingHereYet");
const errorDialog = () => import('@/components/dialogs/error');

import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import Defer from '@/utils/defer';
import PhotoClipper from '@/components/profile/PhotoClipper.vue'
import { extractColorsFromImage } from '@/utils/imageUtils';

export default {
	name: 'artist-profile',
	props: ['id', 'name'],
	components: {
		artistProfileLayout,
		albumGridSkeletonLoader,
		artistProfileAvatar,
		albumGrid,
		ComingSoonDialog,
		areYouThisArtistDialog,
		dialogNeedLogin,
		nothingHereYet,
		errorDialog,
		PhotoClipper,
	},
	mixins: [checkAccessToken, isMobile, Defer()],
	data() {
		return {
			artist: {
				name: '',
				description: '',
			},
			apiUrl: process.env.VUE_APP_API_URL,
			loader: false,
			releasesAlbumsLoader: true,
			changeArtistLoader: false,
			dialogAreYouThisArtistVisible: false,

			viewContent: 'view-profile',
			shareLink: window.location.href,

			tabs: null,
			tabsSection: [
				{index: 0, name: 'Music'},
				{index: 1, name: 'Events'},
				{index: 2, name: 'About'}
			],

			sortByAlbumArtist: [
				'Release Date'
			],
			selectSortByArtistAlbums: 'Release Date',

			backgroundGradient: null,

			ownerID: '',
			groupArtistAlbums: [],
			appearsAlbums: [],
			showComingSoonDialog: false,
			dialogNeedLoginVisible: false,
			artistAlbums: true,
			albums: [],
			isFollow: false,
			followBtnLoader: false,
			messageBtnLoader: false,
			supportBtnLoader: false,
			hoverFollowingButton: false,
			actionButtonsLoader: true,
			errorDialog: false,
			followersCount: null,
      copiedArtistLink: false,
			groupAppearsAlbums: [],
			cacheKey: new Date().getTime(),
			showPhotoClipper: false,
			selectedImage: null,
		}
	},

	watch: {
		ARTIST: {
			handler(newVal, oldVal) {
				if (!oldVal || 
					!newVal || 
					JSON.stringify(oldVal.avatar) !== JSON.stringify(newVal.avatar)) {
					this.getBackground();
				}

				if (newVal && typeof newVal.followersCount !== 'undefined') {
					this.followersCount = newVal.followersCount;
				}
			},
			deep: true
		},
		'artist.name'() {
			if (this.viewContent === 'edit-profile' && this.artist.name !== this.ARTIST.name) {
				let formData = new FormData();
				formData.append('name', this.artist.name);
				this.CHECK_UNIQUE_ARTIST_NAME(formData)
					.catch(err => console.log(`CHECK_UNIQUE_ARTIST_NAME, ${err}`));
			}
		},
		tabs() {
			if (this.tabs !== 2) {
				this.viewContent = 'view-profile';
			}
		}
	},
	computed: {
		...mapGetters(['AUTH_DATA', 'userID', 'ARTIST', 'ARTIST_ALBUMS', 'CHANGE_ARTIST_DATA_STATUS', 'MUSIC_RELEASE', 'USER', 'FILE_URL', 'ARTIST_OWNER', 'APPEARS_ON',
			'ARTIST_FOLLOWERS',
			'ARTIST_PROFILE_RELEASES_ALBUMS',
			'ARTIST_PROFILE_APPEARS_ON_ALBUMS',
			'FACEBOOK',
			'UNIQUE_ARTIST_NAME',
			'HAS_FOLLOWED_ARTIST',
		]),
		// formattedBio() {
		//     return this.ARTIST.description.replace(/\n/g, '<br>');
		// }
	},
	created: function () {
		this.moment = moment;
		this.getArtistData();
		this.getReleasesAlbums();
		this.getAppearsOnAlbums();
	},
	mounted() {
		this.toTop();
		let edit = this.$router.history.current.params.edit;
		if (edit === true) {
			this.editArtistProfile();
		}
		this.getBackground();
	},
	methods: {
		...mapActions(['GET_ARTIST', 'GET_ARTIST_ALBUMS', 'CHANGE_ARTIST_DATA',
			'GET_MUSIC_RELEASE', 'GET_USER', 'ADD_FILE', 'GET_ARTIST_OWNER', 'GET_APPEARS_ON',
			'GET_ARTIST_FOLLOWERS', 'ARTIST_FOLLOW', 'ARTIST_UNFOLLOW',
			'GET_ARTIST_PROFILE_RELEASES_ALBUMS',
			'GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS', 'CHECK_UNIQUE_ARTIST_NAME', 'GET_HAS_FOLLOWED_ARTIST'
		]),
		redirectToUserProfile(userID, userName) {
			// const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			// const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: userName}});
			// window.open(routeData.href, '_blank');
			this.$router.push({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
		},
		async updateBackgroundGradient(imageUrl) {
			try {
				const backgroundElement = this.$el.querySelector('.profile_head_background');
				if (backgroundElement) {
					backgroundElement.classList.remove('visible');
				}

				const colors = await extractColorsFromImage(imageUrl);
				const newGradient = `linear-gradient(180deg, ${colors.topColor} 0%, ${colors.bottomColor} 100%)`;
				
				// Set the background first
				if (backgroundElement) {
					backgroundElement.style.background = newGradient;
				}
				
				// Then update the data property
				this.backgroundGradient = newGradient;

				// Finally make it visible
				setTimeout(() => {
					if (backgroundElement) {
						backgroundElement.classList.add('visible');
					}
				}, 50);
			} catch (error) {
				console.error('Error generating gradient:', error);
				this.backgroundGradient = null;
				
				const backgroundElement = this.$el.querySelector('.profile_head_background');
				if (backgroundElement) {
					backgroundElement.classList.remove('visible');
					backgroundElement.style.background = '';
					backgroundElement.classList.add('default');
				}
			}
		},
		getBackground() {
			if (this.ARTIST?.avatar?.thumbnail) {
				this.updateBackgroundGradient(
					`${this.ARTIST.avatar.thumbnail['160x160']}?cache=${this.cacheKey}`
				);
			} else {
				this.backgroundGradient = null;
			}
		},
		getArtistData() {
			this.loader = true;
			this.actionButtonsLoader = true;

			let artist = this.id || this.name;
			
			this.GET_ARTIST(artist)
				.then(() => {
					if (!this.ARTIST.id) {
						this.$router.push({name: "404"});
						return;
					}
					
					this.artist.name = this.ARTIST.name;
					this.artist.description = this.ARTIST.description;
					this.followersCount = this.ARTIST.followersCount;
					
					const promises = [];

					if (this.ARTIST.ownerId) {
							promises.push(
								this.GET_ARTIST_OWNER(this.ARTIST.ownerId)
									.catch(() => {})
							);
					}

					if (this.AUTH_DATA.accessToken.length) {
							promises.push(
								this.GET_HAS_FOLLOWED_ARTIST(this.ARTIST.id)
									.then(() => {
										this.isFollow = this.HAS_FOLLOWED_ARTIST;
									})
									.catch(() => {})
							);
					}

					return Promise.all(promises);
				})
				.catch(() => {
					this.$router.push({name: "404"});
				})
				.finally(() => {
					this.loader = false;
					this.actionButtonsLoader = false;
				});
		},

		getReleasesAlbums() {
			this.releasesAlbumsLoader = true;
			this.groupArtistAlbums = [];
			let params = {'artist.identifier': this.name, status: 'published'}

			this.GET_ARTIST_PROFILE_RELEASES_ALBUMS(params)
				.then(() => {
					for (let i = 0; i < this.ARTIST_PROFILE_RELEASES_ALBUMS.length; i++) {
						const year = moment(this.ARTIST_PROFILE_RELEASES_ALBUMS[i].releasedAt).year();
						this.groupArtistAlbums[year] = this.groupArtistAlbums[year] || [];
						this.groupArtistAlbums[year].push({[year]: this.ARTIST_PROFILE_RELEASES_ALBUMS[i]});
					}
					this.groupArtistAlbums.reverse();
				})
				.catch(() => {})
				.finally(() => this.releasesAlbumsLoader = false);
		},
		getAppearsOnAlbums() {
			let params = {
				'playList.song.artist': this.name
			}
			this.GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS(params)
				.then(() => {
					this.appearsAlbums = this.ARTIST_PROFILE_APPEARS_ON_ALBUMS.filter(album => album.artist === null);
					
					// Group appears on albums by year
					this.groupAppearsAlbums = [];
					for (let i = 0; i < this.appearsAlbums.length; i++) {
						const year = moment(this.appearsAlbums[i].releasedAt).year();
						this.groupAppearsAlbums[year] = this.groupAppearsAlbums[year] || [];
						this.groupAppearsAlbums[year].push({[year]: this.appearsAlbums[i]});
					}
					this.groupAppearsAlbums.reverse();
				})
				.catch(() => {})
		},

		editArtistProfile() {
			this.viewContent = 'edit-profile';
			this.tabs = 2; // aboutSection
		},
		cancel() {
			this.viewContent = 'view-profile';
			this.tabs = 0; // musicSection
		},

		changeArtistData() {
			this.changeArtistLoader = true;
			// UNIQUE_ARTIST_NAME
			const changeArtistData = {
				id: this.ARTIST.id,
				name: this.artist.name,
				description: this.artist.description,
			};
			this.CHANGE_ARTIST_DATA(changeArtistData)
				.then(() => {
					// this.ARTIST.description.replace(/\n/g, '<br>');
				})
				.catch(err => {
					this.errorDialog = true;
					console.log(`CHANGE_ARTIST_DATA, ${err}`);
				})
				.finally(() => {
					this.changeArtistLoader = false;

					if (this.CHANGE_ARTIST_DATA_STATUS === 'success') {
						this.viewContent = 'view-profile';
					} else {
						this.artist.name = this.ARTIST.name;
						this.artist.description = this.ARTIST.description;
						this.errorDialog = true;
					}
				})
		},
		follow(artistID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;
				this.ARTIST_FOLLOW(artistID)
					.then(result => {
						if (result === 'success') {
							this.isFollow = true;
							this.followersCount = (this.followersCount || 0) + 1;
							
							// Refresh default avatar if needed
							if (this.$refs.artistProfileAvatar && 
								this.$refs.artistProfileAvatar.isImageDefault) {
								this.$refs.artistProfileAvatar.refreshAvatar(new Date().getTime());
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(() => {
						this.errorDialog = true;
					})
					.finally(() => {
						this.followBtnLoader = false;
					});
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},
		unfollow(artistID) {
			if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
				this.followBtnLoader = true;
				this.ARTIST_UNFOLLOW(artistID)
					.then(result => {
						if (result === 'success') {
							this.isFollow = false;
							if (this.followersCount > 0) {
								this.followersCount = this.followersCount - 1;
							}
							
							// Refresh default avatar if needed
							if (this.$refs.artistProfileAvatar && 
								this.$refs.artistProfileAvatar.isImageDefault) {
								this.$refs.artistProfileAvatar.refreshAvatar(new Date().getTime());
							}
						} else {
							this.errorDialog = true;
						}
					})
					.catch(() => {
						this.errorDialog = true;
					})
					.finally(() => {
						this.followBtnLoader = false;
					});
			} else {
				this.dialogNeedLoginVisible = true;
			}
		},
		async copyArtistLink() {
			const formattedName = window.toHumanFormat(this.ARTIST.name);
			const shareUrl = `https://psychurch.org/artist/${formattedName}`;
			
			try {
				// Try Web Share API first if available
				if (navigator.share) {
					try {
						await navigator.share({
							title: this.ARTIST.name,
							url: shareUrl
						});
						return;
					} catch (err) {
						// Don't show error if user just cancelled the share
						if (err.name === 'AbortError') {
							return;
						}
						throw err; // Re-throw other errors to be caught below
					}
				}
				
				// Fallback to clipboard API
				await this.copyToClipboard(shareUrl);
				this.copiedArtistLink = true;
				setTimeout(() => {
					this.copiedArtistLink = false;
				}, 2000);
			} catch (err) {
				console.error('Share/Copy failed:', err);
				this.errorDialog = true;
			}
		},
		// Add helper method for clipboard operations
		async copyToClipboard(text) {
			try {
				// Try modern clipboard API first
				if (navigator.clipboard && navigator.clipboard.writeText) {
					await navigator.clipboard.writeText(text);
					return;
				}
				
				// Fallback to older execCommand method
				const textArea = document.createElement('textarea');
				textArea.value = text;
				textArea.style.position = 'fixed';
				textArea.style.left = '-999999px';
				textArea.style.top = '-999999px';
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();
				
				const successful = document.execCommand('copy');
				textArea.remove();
				
				if (!successful) {
					throw new Error('Failed to copy text');
				}
			} catch (err) {
				console.error('Copy failed:', err);
				throw err;
			}
		},
		toTop() {
			window.scrollTo({
				top: 0,
			});
		},
		updateCacheKey(newKey) {
			this.cacheKey = newKey;
			this.refreshBackground();
		},
		handleSaveImage(imageData) {
			// Here you would typically make an API call to save the image
			// After successful save:
			this.refreshBackground();
		},
		handleImageUpdated(newCacheKey) {
			this.cacheKey = newCacheKey;
			this.getBackground();
			
			if (this.$refs.artistProfileAvatar) {
				this.$refs.artistProfileAvatar.refreshAvatar(this.cacheKey);
			}
		},
		refreshBackground(newCacheKey) {
			const cacheKey = newCacheKey || new Date().getTime();
			this.cacheKey = cacheKey;

			if (this.ARTIST?.avatar?.thumbnail) {
				const imageUrl = `${this.ARTIST.avatar.thumbnail['160x160']}?cache=${cacheKey}`;
				this.updateBackgroundGradient(imageUrl);
			} else {
				this.backgroundGradient = null;
			}

			// Update avatar component if it exists
			if (this.$refs.artistProfileAvatar) {
				this.$refs.artistProfileAvatar.refreshAvatar(cacheKey);
			}
		},
		openPhotoClipper(image) {
			this.selectedImage = image;
			this.showPhotoClipper = true;
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
