<template>
	<div class="albums_list">
		<!-- HEAD GRID ALBUMS-->
		<template>
			<!-- <div class="head_grid_albums" v-if="SEARCH_VIEW_ALBUMS === 'grid'">
				<div class="select_view_albums">
					<button type="button" class="view_list" @click="changeVisibleAlbums('list')" :class="{'active': SEARCH_VIEW_ALBUMS === 'list'}"></button>
					<button type="button" class="view_grid" @click="changeVisibleAlbums('grid')" :class="{'active': SEARCH_VIEW_ALBUMS === 'grid'}"></button>
				</div>
				<div class="sortable">
					<span>Sort by</span>
					<v-menu
						:close-on-content-click="true"
						offset-y
						nudge-left="0"
						nudge-bottom="-3"
						origin="top center"
					>
						<template v-slot:activator="{ on, attrs }">
							<button type="button" class="sort_by_btn" v-bind="attrs" v-on="on" aria-role="list">
								<template v-if="SEARCH_ALBUMS_ORDER_BY.sort === 'title'">Title</template>
								<template v-if="SEARCH_ALBUMS_ORDER_BY.sort === 'artist.name'">Artist</template>
								<template v-if="SEARCH_ALBUMS_ORDER_BY.sort === 'releasedAt'">Release Date</template>
								<span></span>
							</button>
						</template>
						<v-list class="sort_by_list">
							<v-list-item
								v-for="(item, index) in sortByAlbums" :key="index">
								<button type="button" @click="selectSortBy(item)">{{ item }}</button>
							</v-list-item>
						</v-list>
					</v-menu>

				</div>
			</div> -->

			<headListAlbums v-if="SEARCH_VIEW_ALBUMS === 'list'"
			                :selectViewAlbums="true"
			                :which="'searchDetails'"
			                :stateViewAlbums="SEARCH_VIEW_ALBUMS"
			                :updateViewAlbums="'SET_SEARCH_VIEW_ALBUMS'"
			                :stateOrderBy="'SEARCH_ALBUMS_ORDER_BY'"
			                :updateOrderBy="'UPDATE_SEARCH_ALBUMS_ORDER_BY'">
			</headListAlbums>
			<!-- HEAD LIST ALBUMS-->
		</template>

		<div class="wrap_albums" :class="SEARCH_VIEW_ALBUMS">
			<template v-if="SEARCH_VIEW_ALBUMS === 'grid'">

				<template v-if="loader">
					<albumGridSkeletonLoader v-for="index in 30" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
				</template>

				<template v-if="!loader">
					<albumGrid
						v-for="album in SEARCH_ALBUMS_RESULT"
						:key="album.id"
						:album="album"
						:playZone="SEARCH_ALBUMS_RESULT">
					</albumGrid>
				</template>
			</template>

			<template v-if="SEARCH_VIEW_ALBUMS === 'list'">
				<albumListSkeletonLoader v-if="loader" :count="30" :parent="'default_list_albums transparent'"></albumListSkeletonLoader>

				<div class="this_is_premium" v-if="!USER_PERMISSIONS || USER_PERMISSIONS === 'free'">
					<span>List view is a premium feature</span>
					<router-link :to="{name: 'memberships'}" class="c2a_btn">
						Upgrade
                        <span class="arrow">
                            <span class="line_svg"></span>
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            </svg>
                        </span>
					</router-link>
				</div>

				<transition-group v-if="loader === false" class="group-list-album" :class="{disabled: !USER_PERMISSIONS || USER_PERMISSIONS === 'free'}" name="slide">
					<template>
						<album
							v-for="album in SEARCH_ALBUMS_RESULT"
							:key="album.id"
							:albumDetails="album"
							:view="albumExpanded[album.id] ? '' : 'short'"
							@changeVisibilityStatus="changeVisibilityStatus"
							:albumLocation="'SEARCH_ALBUMS_RESULT'">
						</album>
					</template>
				</transition-group>

			</template>
		</div>

		<pagination v-if="SEARCH_ALBUMS_RESULT.length && SEARCH_COUNT_ALBUMS > 30"
		            :count="SEARCH_COUNT_ALBUMS"
		            :currentPage="SEARCH_ALBUMS_CURRENT_PAGE"
		            :commit="'SET_SEARCH_ALBUMS_CURRENT_PAGE'"
		></pagination>

		<v-progress-circular v-if="loader" :size="100" :width="3" color="#20C4F5" indeterminate></v-progress-circular>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import headListAlbums from "../musicReleases/headListAlbums";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import albumListSkeletonLoader from "@/components/small/skeleton-loaders/albumListSkeletonLoader";
import albumGrid from '../musicReleases/albumGrid';
import album from '../musicReleases/album';
import pagination from "@/components/pagination";

export default {
	name: 'albumsSearchList',
	components: {
		headListAlbums,
		albumGridSkeletonLoader,
		albumListSkeletonLoader,
		album,
		albumGrid,
		pagination
	},
	mixins: [],
	data() {
		return {
			loader: false,
			albumExpanded: {},
			sortByAlbums: ['Title', 'Artist', 'Release Date'],
		}
	},
	watch: {
		SEARCH_ALBUMS_ORDER_BY() {
			this.searchAlbums();
			this.$store.commit('SET_SEARCH_ALBUMS_CURRENT_PAGE', 1);
		},
		SEARCH_ALBUMS_CURRENT_PAGE() {
			window.scrollTo({
				top: 0,
				behavior: "instant"
			});
			this.searchAlbums();
		},
		SEARCH_VIEW_ALBUMS() {
			this.$store.commit('SET_ALBUM_EXPANDED', '');
		},
		SEARCH_STRING() {
			this.$store.commit('SET_SEARCH_ALBUMS_CURRENT_PAGE', 1);
		}
	},
	computed: {
		...mapGetters([
            'USER_PERMISSIONS',
			'SEARCH_ALBUMS_RESULT',
			'SEARCH_COUNT_ALBUMS',
			'SEARCH_VIEW_ALBUMS',
			'SEARCH_ALBUMS_ORDER_BY',
			'SEARCH_ALBUMS_CURRENT_PAGE',
			'SEARCH_STRING'
		]),
	},
	mounted() {
		this.toTop();
	},
	methods: {
		...mapActions(['SEARCH_ALBUMS', 'UPDATE_SEARCH_ALBUMS_ORDER_BY']),
		selectSortBy(selectSortByValue) {
			this.selectSortByValue = selectSortByValue;
			let direction = 'DESC';
			switch (selectSortByValue) {
				case 'Title':
					this.$store.dispatch('UPDATE_SEARCH_ALBUMS_ORDER_BY', {sort: 'title', order: `${direction}`});
					break;
				case 'Artist':
					this.$store.dispatch('UPDATE_SEARCH_ALBUMS_ORDER_BY', {sort: 'artist.name', order: `${direction}`});
					break;
				case 'Release Date':
					this.$store.dispatch('UPDATE_SEARCH_ALBUMS_ORDER_BY', {sort: 'releasedAt', order: `${direction}`});
					break;
			}
		},
		changeVisibilityStatus(id) {
			let [album] = Object.keys(this.albumExpanded).filter(expanded => this.albumExpanded[expanded]);
			if (+id !== +album) {
				this.albumExpanded[album] = false;
			}
			this.albumExpanded[id] = !this.albumExpanded[id];
		},
		changeVisibleAlbums(value) {
			this.$store.commit('SET_SEARCH_VIEW_ALBUMS', value);
		},
		searchAlbums() {
			this.loader = true;
			this.toTop();
			const search = {'title': this.SEARCH_STRING};
			const page = {'page': this.SEARCH_ALBUMS_CURRENT_PAGE};
			const onlyPublished = {status: 'published'}
			let params = Object.assign(onlyPublished, search, page, this.SEARCH_ALBUMS_ORDER_BY);
			this.SEARCH_ALBUMS(params)
				.then(() => {

				})
				.catch(err => console.log(`searchAlbums, ${err}`))
				.finally(() => {
					this.loader = false;
				})
		},
		toTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
	}
}
</script>

<style scoped>

</style>
