<template>
    <v-dialog
        :value="value" @input="$emit('input')"
        max-width="574">

        <div class="change_email_dialog">
            <h2 class="header">
                Change Email
            </h2>
            <div class="input_email">
                <input type="text" placeholder="Email" v-model="email">
	            <div class="error-msg" v-if="!$v.email.$error && !$v.email.maxLength">Email must be 128 characters or less</div>
            </div>
            <div class="wrap_actions">
                <button type="button" class="tetriatary_btn small" v-ripple @click="dialog = false">Cancel</button>
                <div type="button" class="primary_btn small disabled" v-if="!$v.email.$error && !$v.email.maxLength">Verify</div>
                <button type="button" class="primary_btn small" v-else v-ripple @click="changeEmail">Verify</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {email, maxLength, required} from "vuelidate/lib/validators";

export default {
    name: 'changeEmail',
    props: ['value'],
    data() {
        return {
            email: ''
        }
    },
	validations: {
		email: {
			email,
			required,
			maxLength: maxLength(128)
		}
	},
    computed: {
        ...mapGetters(['PROFILE']),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    mounted() {
            this.getProfile()
                .then(() => {
                    console.log('get profile', 'changeEmail');
                    this.email = this.PROFILE.email;
                })
                .catch(err => console.log(`change email - getProfile, ${err}`));
    },
    methods: {
        ...mapActions({
            changeUserEmail: 'CHANGE_EMAIL',
            getProfile: 'GET_PROFILE'
        }),


        changeEmail() {
            this.changeUserEmail(this.email);
        }
    }
}
</script>

<style lang="scss">
.change_email_dialog {
    background-color: #282C47;
    box-shadow: 0 2.38509px 4.77019px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 16px 32px 32px;

    .header {
        display: flex;
        justify-content: center;
        color: #dbdbdb;
        font-size: 18px;
        height: 35px;
        border-bottom: 1px solid rgba(#5F6986, .3);
        margin-bottom: 15px;
    }

    .input_email {
        margin-bottom: 32px;

        > input {
            width: 100%;
            height: 52px;
            padding: 0 16px;
            background: #050C1E;
            border: 1px solid #282C47;
            border-radius: 5px;
            color: #f1f1f1;
            font-size: 16px;
        }
	    .error-msg {
		    color: #E94E4E;
		    font-size: 12px;
		    padding-left: 7px;
	    }
    }

    .wrap_actions {
        display: flex;
        justify-content: center;

        > button, > div {
            width: 210px;

            &:first-child {
                margin-right: 16px;
            }
        }
    }
}
</style>
