<template>
  <div>
    <v-bottom-sheet 
      :content-class="'sub_genres_dialog sub_genres_wrap'" 
      v-model="localDialog"
    >
      <template v-slot:activator="{ on }">
        <div class="mobile_filter_item" v-on="on">
          <span class="top">Subgenre</span>
          <span class="bottom">{{ subgenre.id ? subgenre.name : 'All'}}</span>
        </div>
      </template>
      <v-sheet 
        class="text-left subGenresDialog" 
        :style="{ transform: `translateY(${sheetTransform}px)`, transition: isDragging ? 'none' : 'transform 0.3s ease' }"
      >
        <div class="style_dialog subGenres"
             @touchstart.passive="handleTouchStart"
             @touchmove.passive="handleTouchMove"
             @touchend.passive="handleTouchEnd">
          <div class="sheet-handle"></div>
          <div class="header_container">
            <h2 class="header">Choose Subgenre</h2>
          </div>
          
          <div class="subGenresItems">
            <button type="button" class="button" @click="selectSubgenre({})" :class="{'active': !subgenre.id}">All</button>
            <button 
              class="button" 
              :class="{'active': (subgenre && subgenre.id === item.id)}" 
              v-for="item of subgenres" 
              :key="item.id" 
              type="button" 
              @click="selectSubgenre(item)"
            >
              {{ item.name }}
            </button>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'SubgenreFilterCard',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    subgenres: {
      type: Array,
      default: () => []
    },
    filterName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localDialog: false,
      subgenre: {},
      touchStartY: 0,
      sheetTransform: 0,
      isDragging: false,
      initialSheetHeight: 0
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.subgenre = newValue;
      },
      immediate: true
    },
    dialog(newValue) {
      this.localDialog = newValue;
      if (newValue) {
        this.resetSheet();
      }
    },
    localDialog(newValue) {
      this.$emit('update:dialog', newValue);
      if (newValue) {
        this.resetSheet();
      }
    }
  },
  methods: {
    resetSheet() {
      this.sheetTransform = 0;
      this.isDragging = false;
      this.initialSheetHeight = 0;
    },
    selectSubgenre(subgenre) {
      this.$emit('input', subgenre);
      this.$emit('select', subgenre);
      this.localDialog = false;
    },
    handleTouchStart(e) {
      if (e.target.closest('button') || e.target.closest('.subGenresItems')) {
        return;
      }
      
      const sheet = e.target.closest('.style_dialog');
      if (sheet) {
        this.initialSheetHeight = sheet.getBoundingClientRect().height;
      }
      
      this.isDragging = true;
      this.touchStartY = e.touches[0].clientY;
      this.sheetTransform = 0;
    },
    handleTouchMove(e) {
      if (!this.isDragging) return;
      
      const currentY = e.touches[0].clientY;
      const deltaY = currentY - this.touchStartY;
      
      if (deltaY > 0) {
        const resistance = 0.7;
        const resistedDelta = deltaY * resistance;
        this.sheetTransform = resistedDelta;
      }
    },
    handleTouchEnd() {
      if (!this.isDragging) return;
      
      const threshold = this.initialSheetHeight * 0.2;
      const velocity = Math.abs(this.sheetTransform / 16);
      
      if (this.sheetTransform > threshold || velocity > 10) {
        this.localDialog = false;
      } else {
        requestAnimationFrame(() => {
          this.sheetTransform = 0;
        });
      }
      
      this.isDragging = false;
    }
  }
};
</script>

<style scoped>
.mobile_filter_item {
  width: calc((100vw - 40px) / 3);
  border-left: 1px solid #2E3548;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile_filter_item .top {
  color: #8591B1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile_filter_item .bottom {
  color: #FFF;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.sub_genres_dialog {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}

.subGenresDialog {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  will-change: transform;
  transform-origin: bottom;
  touch-action: pan-x pan-y;
}

.style_dialog.subGenres {
  background-color: #1E2335;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-top: env(safe-area-inset-top);
  position: relative;
  padding-top: 8px;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  touch-action: pan-x pan-y;

  .sheet-handle {
    width: 36px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .header_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    position: relative;
    flex: none;

    &::after {
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 0.5px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .header {
      color: #FFF;
      font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "SF Pro Display", system-ui;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      text-align: center;
      background-image: none;
      padding-bottom: 0;
    }
  }

  .subGenresItems {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 8px 16px;
    padding-bottom: calc(80px + env(safe-area-inset-bottom));
    -webkit-overflow-scrolling: touch;
    min-height: 0;
  }
}

.style_dialog.subGenres .header_container .close {
  display: none;
}

.style_dialog.subGenres .subGenresItems {
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}

.style_dialog.subGenres .subGenresItems::-webkit-scrollbar {
  display: none;
}

.style_dialog.subGenres .subGenresItems button {
  border: 1px solid #5F6986;
  border-radius: 12px;
  padding: 11px 15px;
  margin: 5px 0;
  color: white;
  background: transparent;
}

.style_dialog.subGenres .subGenresItems button.active {
  background-color: #5F6986;
}


:deep(.v-bottom-sheet-content) {
  box-shadow: none !important;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  will-change: transform;
  transform-origin: bottom;
}
</style>