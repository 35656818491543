<template>
    <privateProfileLayout>
        <div class="private_profile" data-page="private-profile" :data-edit-mode="viewContent === 'edit-profile'">
            <div class="content-page">
                <div class="head_profile">
                    <!-- Background div should be first child -->
                    <div class="profile_head_background" 
                         :class="{
                             'default': isImageDefault,
                             'visible': !isImageDefault && backgroundGradient
                         }"
                         :style="!isImageDefault ? {
                             background: backgroundGradient
                         } : null">
                    </div>

                    <!-- Loader -->
                    <div class="profile-header-container" v-if="loader">
                        <headSkeletonLoader :parent="'private-profile'"></headSkeletonLoader>
                    </div>

                    <!-- Content -->
                    <div class="profile-header-container" v-else>
                        <privateProfileAvatar @avatar-updated="refreshBackground"></privateProfileAvatar>
                        <div class="user_info">
                            <!-- VIEW PROFILE -->
                            <div class="user_name" :class="{'mobile': mobile}">
                                <div class="name-badge-wrapper">
                                    {{ USER.fullName }}
                                    <template v-if="PROFILE && (PROFILE.subscriptionPlan === 'premium' || PROFILE.isFounder === true)">
                                        <v-tooltip top close-delay="0" transition="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="profile_badge" v-bind="attrs" v-on="on"
                                                     :class="{
                                                         'premium': PROFILE.subscriptionPlan === 'premium', 
                                                         'founder': PROFILE.isFounder === true
                                                     }">
                                                </div>
                                            </template>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === false">Premium account</div>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === true">Founder's account</div>
                                        </v-tooltip>
                                    </template>
                                </div>
                            </div>

                            <!-- EDIT-PROFILE -->
                            <template v-if="viewContent === 'edit-profile' && tabs === 2">
                                <div class="input-style-4">
                                    <label for="full-name">Full Name</label>
                                    <input type="text" id="full-name" required v-model="changeData.fullName">
                                </div>
                                <div class="input-style-4">
                                    <label for="address">Location</label>

                                    <vue-google-autocomplete
                                            v-model="changeData.address"
                                            id="map"
                                            classname="form-control"
                                            placeholder="City, state"
                                            types="(cities)"
                                            v-on:keyup="keypressLocation"
                                            v-on:placechanged="getAddressData">
                                    </vue-google-autocomplete>
                                </div>

                                <div class="about">
<!--                                    <label for="">Services</label>-->
<!--                                    <div class="wrap_checkbox_roles">-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="dj" value="Dj" v-model="userRoles">-->
<!--                                            <label for="dj">Dj</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="producer" value="Producer" v-model="userRoles">-->
<!--                                            <label for="producer">Producer</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="promoter" value="Promoter" v-model="userRoles">-->
<!--                                            <label for="promoter">Promoter</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="vendor" value="Vendor" v-model="userRoles">-->
<!--                                            <label for="vendor">Vendor</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="deco-artist" value="Deco Artist" v-model="userRoles">-->
<!--                                            <label for="deco-artist">Deco Artist</label>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </template>
                            <template v-else>
                                <div class="user_location" v-if="USER.address">
                                    {{ USER.address }}
                                </div>
                                <div class="follows">
                                    <div class="followers">
                                        <span class="count">{{ USER.followersCount }}</span>
                                        <router-link :to="{name: 'manageFollowers'}">Followers</router-link>
                                    </div>
                                    <div class="following">
                                        <span class="count">{{ USER.followingCount }}</span>
                                        <router-link :to="{name: 'following'}">Following</router-link>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="wrap_profile_music_events">
                    <div class="head_profile_music_events">
                        <v-container>
                            <v-tabs
                                    background-color="transparent"
                                    color="#fff"
                                    v-model="tabs">
                                <v-tab v-for="tab in tabsSections" :key="tab.index">
                                    {{ tab.name }}
                                </v-tab>
                            </v-tabs>
                            <div class="actions">
                                <button v-if="viewContent !== 'edit-profile'" type="button" class="edit_profile" @click="editProfile" v-ripple>Edit profile
                                </button>
                                <!-- more button -->
                                <v-menu
                                        transition="slide-y-transition"
                                        :close-on-content-click="true"
                                        offset-y
                                        nudge-left="21"
                                        nudge-bottom="10"
                                        origin="top center"
                                        content-class="menu-pointer charcoal">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
                                            <span class="material-icons">more_vert</span>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <!-- popup share link -->
                                        <v-list-item>
                                            <v-dialog
                                                    transition="dialog-bottom-transition"
                                                    max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <button type="button" v-bind="attrs" v-on="on">Share</button>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <div class="content_popup_share">
                                                        <div class="head_popup">Share</div>
                                                        <div class="input_box">
                                                            <input type="text" id="input-share-link"
                                                                   v-model="shareLink">
                                                            <button type="button" @click="copyUserLink">Copy</button>
                                                        </div>
                                                        <button type="button" @click="dialog.value = false"
                                                                class="close">Close
                                                        </button>
                                                    </div>
                                                </template>
                                            </v-dialog>
                                        </v-list-item>
                                        <!-- Report -->
                                        <v-list-item>
                                            <button type="button" class="report">Report</button>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </v-container>
                    </div>
                    <div class="content_profile_music_events">
                        <v-tabs-items touchless v-model="tabs">
                            <v-tab-item> <!-- MUSIC SECTION -->
                                <div class="music_section">
                                    <!-- ************** RECENTLY PLAYED ALBUMS ************** -->
                                    <div class="recently_played" :class="{'empty': !recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length}">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently played</template>
                                                <template #action>
                                                    <v-btn outlined @click="showComingSoonDialog = true">History</v-btn>
                                                </template>
                                            </ContentHeader>

                                            <div class="albums_row">
                                                <template v-if="recentlyPlayedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyPlayedLoader && PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
	                                        <nothingHereYet v-if="!recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length"></nothingHereYet >
                                        </v-container>
                                    </div>

                                    <!-- ************** RECENTLY LIKED ALBUMS ************** -->
                                    <div class="recently_liked" :class="{'empty': !recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length}">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently liked</template>
                                                <template #action>
                                                    <v-btn outlined to="/library">Library</v-btn>
                                                </template>
                                            </ContentHeader>
                                            <div class="albums_row">
                                                <template v-if="recentlyLikedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyLikedLoader && PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :small="true"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
	                                        <nothingHereYet v-if="!recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
                                        </v-container>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- EVENTS SECTION -->
                                <div class="events_section">
                                    <div class="uploaded_events">
                                        <v-container>
                                            <ContentHeader>
                                                <template #title>Recently viewed</template>
                                            </ContentHeader>
                                            <nothingHereYet></nothingHereYet>
                                        </v-container>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- ABOUT SECTION -->
                                <div class="about_section" :data-edit-mode="viewContent === 'edit-profile'">
                                    <v-container>
                                        <div class="wrap_about_section">
                                            <template v-if="viewContent === 'edit-profile'">
                                                <div class="user-roles">
                                                    <button 
                                                        type="button" 
                                                        class="role-tag" 
                                                        :class="{ active: changeData.isProducer }"
                                                        @click="toggleRole('Producer')">
                                                        <span class="icon">🎵</span>
                                                        Producer
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        class="role-tag" 
                                                        :class="{ active: changeData.isPromoter }"
                                                        @click="toggleRole('Promoter')">
                                                        <span class="icon">📣</span>
                                                        Promoter
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        class="role-tag" 
                                                        :class="{ active: changeData.isVendor }"
                                                        @click="toggleRole('Vendor')">
                                                        <span class="icon">🏷️</span>
                                                        Vendor
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        class="role-tag" 
                                                        :class="{ active: changeData.isDecoArtist }"
                                                        @click="toggleRole('DecoArtist')">
                                                        <span class="icon">🎨</span>
                                                        Deco Artist
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        class="role-tag" 
                                                        :class="{ active: changeData.isDj }"
                                                        @click="toggleRole('Dj')">
                                                        <span class="icon">🎧</span>
                                                        DJ
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-else-if="hasAnyRole">
                                                <div class="user-roles">
                                                    <div class="role-item" v-if="PROFILE.isProducer">
                                                        <span class="icon">🎵</span> Producer
                                                    </div>
                                                    <div class="role-item" v-if="PROFILE.isPromoter">
                                                        <span class="icon">📣</span> Promoter
                                                    </div>
                                                    <div class="role-item" v-if="PROFILE.isVendor">
                                                        <span class="icon">🏷️</span> Vendor
                                                    </div>
                                                    <div class="role-item" v-if="PROFILE.isDecoArtist">
                                                        <span class="icon">🎨</span> Deco Artist
                                                    </div>
                                                    <div class="role-item" v-if="PROFILE.isDj">
                                                        <span class="icon">🎧</span> DJ
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </v-container>

                                    <v-container>
                                        <!-- Bio Section -->
                                        <ContentHeader v-if="viewContent !== 'edit-profile'">
                                            <template #title>Bio</template>
                                        </ContentHeader>
                                        <div class="wrap_about_section">
                                            <template v-if="viewContent === 'edit-profile'">
                                                <TextareaAutosize v-model="changeData.description"></TextareaAutosize>
                                                <div class="wrap_action_btn">
                                                    <button type="button" class="save" v-ripple @click="saveUserData">Save</button>
                                                    <button type="button" class="close" v-ripple @click="cancel">Cancel</button>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <template v-if="USER.description">
                                                    <div v-html="formattedBio"></div>
                                                </template>
                                                <template v-else>
                                                    <nothingHereYet></nothingHereYet>
                                                </template>
                                            </template>
                                        </div>
                                    </v-container>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                </div>
            </div>
            <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
        </div>
    </privateProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import ContentHeader from '@/components/ContentHeader.vue'
import privateProfileLayout from "@/layouts/profiles/privateProfileLayout.vue";
import headSkeletonLoader from "@/components/small/skeleton-loaders/headSkeletonLoader";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import privateProfileAvatar from "@/components/profile/privateProfileAvatar";
import albumGrid from '@/components/musicReleases/albumGrid';

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import Defer from "@/utils/defer";
import nothingHereYet from "@/components/small/nothingHereYet";
import { extractColorsFromImage } from '@/utils/imageUtils';

export default {
    name: "private-profile",
    components: {
        ContentHeader,
        privateProfileLayout,
        privateProfileAvatar,
        albumGrid,
        ComingSoonDialog,
        VueGoogleAutocomplete,
        headSkeletonLoader,
        albumGridSkeletonLoader,
        nothingHereYet
    },
    mixins: [checkAccessToken, isMobile, Defer()],
    data() {
        return {
			changeData: {
				fullName: '',
				address: '',
				description: '',
				isProducer: false,
				isPromoter: false,
				isVendor: false,
				isDecoArtist: false,
				isDj: false,
			},
            showComingSoonDialog: false,
            viewContent: 'my-profile',
            shareLink: '',
            smallImageUrl: '',
            isImageDefault: false,

            tabsSections: [
                {index: 0, name: 'Music'},
                {index: 1, name: 'Events'},
                {index: 2, name: 'About'}
            ],
            tabs: null,

            showDescription: false, // mobile

            loader: false,
            changeDataLoader: false,
            recentlyPlayedLoader: false,
            recentlyLikedLoader: false,
            uploadedAlbumsLoader: false,
            location: '',

            mobile: false,
            avatarData: {
                isDefault: true,
                smallUrl: '',
                key: new Date().getTime()
            },
            backgroundGradient: null,
        }
    },

    watch: {
		'USER.fullName': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.fullName -redirect login');
				this.$router.push({name: 'login'});
			}
		},
	    'USER.address': function (val) {
		    if (!this.checkAccessToken()) {
			    console.log('watch USER.address -redirect login');
			    this.$router.push({name: 'login'});
		    }
	    },
		'USER.description': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.description -redirect login');
				this.$router.push({name: 'login'});
			}
		},
        PROFILE: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.checkMobile();
                        this.$forceUpdate();
                    });
                }
            }
        },
        PROFILE_LOGO: {
            async handler(newLogo) {
                if (newLogo) {
                    const logoUrl = newLogo['240x270'] || Object.values(newLogo)[0];
                    if (logoUrl) {
                        this.avatarData = {
                            isDefault: false,
                            smallUrl: logoUrl,
                            key: new Date().getTime()
                        };
                        this.smallImageUrl = `${logoUrl}?cache=${this.avatarData.key}`;
                        this.isImageDefault = false;
                        await this.$nextTick();
                        await this.updateBackgroundGradient(this.smallImageUrl);
                    } else {
                        await this.$nextTick();
                        this.setDefaultImage();
                    }
                } else {
                    await this.$nextTick();
                    this.setDefaultImage();
                }
            },
            immediate: true
        },
        tabs(newValue) {
            if (newValue !== 2 && this.viewContent === 'edit-profile') {
                this.confirmCancel();
            }
        },
    },

    computed: {
        ...mapGetters([
            'PROFILE',
            'USER', 'AUTH_DATA', 'userID',
            'YOUR_ARTISTS',
            'PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS',
            'PROFILE_USER_LOGO',
            'USER',
            'PROFILE_LOGO',
        ]),
        formattedBio() {
            return this.USER.description.replace(/\n/g, '<br>');
        },
        hasAnyRole() {
            return this.PROFILE?.isProducer ||
                this.PROFILE?.isPromoter ||
                this.PROFILE?.isVendor ||
                this.PROFILE?.isDecoArtist ||
                this.PROFILE?.isDj;
        },
        backgroundImageUrl() {
            return this.USER?.imageUrl || this.PROFILE?.imageUrl || null;
        }
    },
    mounted() {
        this.debouncedCheckMobile = this.debounce(this.checkMobile, 250);
        window.addEventListener('resize', this.debouncedCheckMobile);
        
        this.checkMobile();
        this.getData();
        this.getRecentlyPlayed();
        this.getRecentlyLiked();
        
        console.log('Initial PROFILE_LOGO:', this.PROFILE_LOGO);
    },
    methods: {
        ...mapActions([
            'GET_USER', 'CHANGE_USER_DATA', 'GET_YOUR_ARTISTS',
            'GET_RECENTLY_PLAYED',
            'GET_PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS'
        ]),
	    redirectToArtistPage(artistId, artistName) {
		    this.$router.push({
		        name: 'artist-profile', 
		        params: {
		            id: artistId, 
		            name: window.toHumanFormat(artistName)
		        }
		    });
	    },
		getUserData() {
			this.loader = true;
			this.GET_USER(this.userID)
				.then(() => {
					this.changeData.fullName = this.USER.fullName;
					this.changeData.address = this.USER.address;
					this.changeData.description = this.USER.description;
					this.changeData.isProducer = this.PROFILE.isProducer;
					this.changeData.isPromoter = this.PROFILE.isPromoter;
					this.changeData.isVendor = this.PROFILE.isVendor;
					this.changeData.isDecoArtist = this.PROFILE.isDecoArtist;
					this.changeData.isDj = this.PROFILE.isDj;

					if (this.PROFILE_LOGO && typeof this.PROFILE_LOGO === 'object') {
						const logoUrl = this.PROFILE_LOGO['240x270'] || Object.values(this.PROFILE_LOGO)[0];
						if (logoUrl) {
							this.smallImageUrl = logoUrl;
							this.isImageDefault = false;
							console.log('Setting background image to:', this.smallImageUrl);
						} else {
							this.setDefaultImage();
						}
					} else {
						this.setDefaultImage();
					}
				})
				.catch(err => {
					console.log(`private-profile, GET_USER, ${err}`);
				})
				.finally(() => {
					this.loader = false;
				});
		},
        getData() {
            this.loader = true;
            this.uploadedAlbumsLoader = true;
            this.shareLink = `${window.location.hostname}/user/${this.userID}`;

	        this.getUserData();
            this.GET_YOUR_ARTISTS({owner: this.userID})
                .then(() => {
                });
        },

        getRecentlyPlayed() {
            this.recentlyPlayedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyPlayed, ${err}`))
                .finally(() => {
                    this.recentlyPlayedLoader = false;
                })
        },
        getRecentlyLiked() {
            this.recentlyLikedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyLiked, ${err}`))
                .finally(() => {
                    this.recentlyLikedLoader = false;
                })
        },

        saveUserData() {
            this.loader = true;
            this.changeDataLoader = true;

            // Update PROFILE data immediately before view change
            this.PROFILE.isProducer = this.changeData.isProducer;
            this.PROFILE.isPromoter = this.changeData.isPromoter;
            this.PROFILE.isVendor = this.changeData.isVendor;
            this.PROFILE.isDecoArtist = this.changeData.isDecoArtist;
            this.PROFILE.isDj = this.changeData.isDj;
            
            // Update USER data immediately
            this.USER.fullName = this.changeData.fullName;
            this.USER.address = this.changeData.address;
            this.USER.description = this.changeData.description;

            // Change view mode after updating data
            this.viewContent = 'my-profile';

            const userData = {
                id: this.userID,
                fullName: this.changeData.fullName,
                address: this.changeData.address,
                description: this.changeData.description,
                isProducer: this.changeData.isProducer,
                isPromoter: this.changeData.isPromoter,
                isVendor: this.changeData.isVendor,
                isDecoArtist: this.changeData.isDecoArtist,
                isDj: this.changeData.isDj,
            };

            this.CHANGE_USER_DATA(userData)
                .catch(err => {
                    console.log(`saveUserData, ${err}`);
                    // Revert changes if API call fails
                    this.getUserData();
                })
                .finally(() => {
                    this.changeDataLoader = false;
                    this.loader = false;
                });
        },

        keypressLocation(event) {
			console.log('keypressLocation', event.target.value);
			if (event.target.value) {
				this.changeData.address = event.target.value;
			}
        },
        getAddressData: function (addressData, placeResultData) {
			console.log('getAddressData', addressData);
            if (addressData.country === 'United States') {
                this.changeData.address = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
            } else {
                this.changeData.address = `${addressData.locality}, ${addressData.country}`;
            }
        },
        editProfile() {
	        let accessToken = localStorage.getItem('accessToken');
			if (accessToken) {
				this.viewContent = 'edit-profile';
				this.tabs = 2; // aboutSection
			} else {
				console.log('editProfiles -redirect login');
				this.$router.push({name: 'login'});
			}
        },
        cancel() {
            this.viewContent = 'my-profile';
            this.changeData.fullName = this.USER.fullName;
            this.changeData.address = this.USER.address;
            this.changeData.description = this.USER.description;
            this.changeData.isProducer = this.PROFILE.isProducer;
            this.changeData.isPromoter = this.PROFILE.isPromoter;
            this.changeData.isVendor = this.PROFILE.isVendor;
            this.changeData.isDecoArtist = this.PROFILE.isDecoArtist;
            this.changeData.isDj = this.PROFILE.isDj;
        },
        copyUserLink() {
            navigator.clipboard.writeText(this.shareLink);
        },
        toggleDescription() {
            this.showDescription = !this.showDescription;
        },
        setDefaultImage() {
            this.smallImageUrl = require('@/assets/image/svg/default_avatar.svg');
            this.isImageDefault = true;
            this.backgroundGradient = null;

            this.$nextTick(() => {
                const backgroundElement = this.$el?.querySelector('.profile_head_background');
                if (backgroundElement) {
                    backgroundElement.classList.remove('visible');
                    backgroundElement.style.background = '';
                    backgroundElement.classList.add('default');
                }
            });
        },
        checkMobile() {
            this.mobile = window.innerWidth <= 767;
        },
        debounce(fn, delay) {
            let timeoutId;
            return function (...args) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn.apply(this, args), delay);
            };
        },
        async updateBackgroundGradient(imageUrl) {
            try {
                await this.$nextTick();
                const backgroundElement = this.$el?.querySelector('.profile_head_background');
                
                if (backgroundElement) {
                    // Update gradient
                    const colors = await extractColorsFromImage(imageUrl);
                    const newGradient = `linear-gradient(180deg, ${colors.topColor} 0%, ${colors.bottomColor} 100%)`;
                    backgroundElement.style.setProperty('--custom-gradient', newGradient);
                    
                    // Add visible class to trigger transition
                    backgroundElement.classList.remove('default');
                    backgroundElement.classList.add('visible');
                }
            } catch (error) {
                console.error('Error generating gradient:', error);
                this.setDefaultImage();
            }
        },
        async refreshBackground() {
            this.avatarData.key = new Date().getTime();
            const logoUrl = this.PROFILE_LOGO['240x270'] || Object.values(this.PROFILE_LOGO)[0];
            if (logoUrl) {
                const backgroundElement = this.$el.querySelector('.profile_head_background');
                if (backgroundElement) {
                    backgroundElement.classList.remove('visible');
                    backgroundElement.classList.remove('default');
                }
                
                this.smallImageUrl = `${logoUrl}?cache=${this.avatarData.key}`;
                this.isImageDefault = false;
                await this.updateBackgroundGradient(this.smallImageUrl);
            } else {
                this.setDefaultImage();
            }
        },
        hasUnsavedChanges() {
            return this.changeData.fullName !== this.USER.fullName ||
                   this.changeData.address !== this.USER.address ||
                   this.changeData.description !== this.USER.description ||
                   this.changeData.isProducer !== this.PROFILE.isProducer ||
                   this.changeData.isPromoter !== this.PROFILE.isPromoter ||
                   this.changeData.isVendor !== this.PROFILE.isVendor ||
                   this.changeData.isDecoArtist !== this.PROFILE.isDecoArtist ||
                   this.changeData.isDj !== this.PROFILE.isDj;
        },

        async confirmCancel() {
            if (this.hasUnsavedChanges()) {
                if (confirm('You have unsaved changes. Do you want to save them before leaving?')) {
                    await this.saveUserData();
                }
            }
            this.viewContent = 'my-profile';
            this.changeData.fullName = this.USER.fullName;
            this.changeData.address = this.USER.address;
            this.changeData.description = this.USER.description;
            this.changeData.isProducer = this.PROFILE.isProducer;
            this.changeData.isPromoter = this.PROFILE.isPromoter;
            this.changeData.isVendor = this.PROFILE.isVendor;
            this.changeData.isDecoArtist = this.PROFILE.isDecoArtist;
            this.changeData.isDj = this.PROFILE.isDj;
        },
        toggleRole(role) {
            switch(role) {
                case 'Producer':
                    this.changeData.isProducer = !this.changeData.isProducer;
                    break;
                case 'Promoter':
                    this.changeData.isPromoter = !this.changeData.isPromoter;
                    break;
                case 'Vendor':
                    this.changeData.isVendor = !this.changeData.isVendor;
                    break;
                case 'DecoArtist':
                    this.changeData.isDecoArtist = !this.changeData.isDecoArtist;
                    break;
                case 'Dj':
                    this.changeData.isDj = !this.changeData.isDj;
                    break;
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.debouncedCheckMobile);
    }
}
</script>

<style lang="scss">
.user-roles {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 8px;

    .role-item, .role-tag {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        background: rgba(26, 31, 52, 0.5);
        border: 1px solid rgba(116, 129, 166, 0.2);
        border-radius: 8px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
        font-weight: 500;

        .icon {
            font-size: 16px;
        }
    }

    .role-tag {
        cursor: pointer;
        transition: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        position: relative;
        transform-origin: center center;
        will-change: transform;
        touch-action: manipulation;

        &.active {
            background: rgba(32, 196, 245, 0.15);
            border-color: #20C4F5;
            color: #20C4F5;
            animation: selectPop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        // Hover states only on non-touch devices
        @media (hover: hover) and (pointer: fine) {
            transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
            
            &:hover {
                background: rgba(32, 196, 245, 0.1);
                border-color: rgba(32, 196, 245, 0.5);
                color: #fff;
            }

            &.active:hover {
                background: rgba(32, 196, 245, 0.2);
            }
        }

        &:focus {
            outline: none;
        }

        @media (pointer: fine) {
            &:focus {
                box-shadow: 0 0 0 2px rgba(32, 196, 245, 0.3);
            }
        }

        &:active {
            background: rgba(26, 31, 52, 0.5);
            &.active {
                background: rgba(32, 196, 245, 0.15);
            }
        }
    }
}

@keyframes selectPop {
    0% {
        transform: scale(0.98);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
}
</style>
