import axios from 'axios';

// Cache the data source configuration
let cachedDataSource = null;
let elasticPathCache = null;

function initializeDataSourceConfig() {
    if (!cachedDataSource) {
        try {
            console.log('[Search] Initializing at store creation...');
            cachedDataSource = JSON.parse(localStorage.getItem('DataSource')) || { db: 'api', auto: false };
            elasticPathCache = (!cachedDataSource || !cachedDataSource.db || cachedDataSource.db === 'api') ? '' : 'search/';
            console.log('[Search] Initial configuration complete');
        } catch (err) {
            console.error('[Search] Error parsing DataSource:', err);
            cachedDataSource = { db: 'api', auto: false };
            elasticPathCache = '';
        }
    }
    return { source: cachedDataSource, elasticPath: elasticPathCache };
}

function getDataSourceConfig() {
    return { source: cachedDataSource, elasticPath: elasticPathCache };
}

const initialState = () => ({
    searchString: '',
    searchLoader: false,
    searchDetailsContent: '',
    isInitialized: false,

    // artists
    searchArtistsResult: [],
    searchCountArtists: '',
    searchArtistsCurrentPage: 1,

    // albums
    searchAlbumsResult: [],
    searchCountAlbums: '',
    searchAlbumsCurrentPage: 1,
    searchViewAlbums: 'grid',
    searchAlbumsOrderBy: {sort: 'releasedAt', order: `DESC`},

    // tracks
    searchTracksResult: [],
    searchCountTracks: '',
    searchTracksCurrentPage: 1,
    searchTracksOrderBy: {'order[releasedAt]': `DESC`},

    // record labels
    searchRecordLabelsResult: [],
    searchCountRecordLabels: '',
    searchRecordLabelsCurrentPage: 1,
});

// Initialize immediately when the store module is created
initializeDataSourceConfig();

export default {
    state: {
        ...initialState(),
        isInitialized: true // Set to true since we initialize at creation
    },
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
            // Reset data source cache on state reset
            cachedDataSource = null;
            elasticPathCache = null;
            state.isInitialized = false;
        },
        SET_INITIALIZED(state, value) {
            state.isInitialized = value;
        },
        UPDATE_SEARCH_STRING: (state, string) => {
            state.searchString = string;
        },
        UPDATE_SEARCH_DETAILS_CONTENT: (state, data) => {
          state.searchDetailsContent = data;
        },

        // artists
        SET_SEARCH_ARTISTS: (state, data) => {
            state.searchArtistsResult = data['hydra:member'];
            state.searchCountArtists = data['hydra:totalItems'];
        },
        SET_SEARCH_ARTISTS_CURRENT_PAGE: (state, data) => {
            state.searchArtistsCurrentPage = data;
        },


        // albums
        SET_SEARCH_ALBUMS: (state, data) => {
            state.searchAlbumsResult = data.items;
            state.searchCountAlbums = data.count_results;
        },
        SET_SEARCH_VIEW_ALBUMS: (state, data) => {
            state.searchViewAlbums = data;
        },
        SET_SEARCH_ALBUMS_ORDER_BY: (state, orderBy) => {
            state.searchAlbumsOrderBy = orderBy;
        },
        SET_SEARCH_ALBUMS_CURRENT_PAGE: (state, data) => {
            state.searchAlbumsCurrentPage = data;
        },


        // tracks
        SET_SEARCH_TRACKS: (state, data) => {
            state.searchTracksResult = data['hydra:member'];
            state.searchCountTracks = data['hydra:totalItems'];
        },
        UPDATE_SEARCH_LOADER: (state, data) => {
            state.searchLoader = data;
        },
        SET_SEARCH_TRACKS_ORDER_BY: (state, orderBy) => {
          state.searchTracksOrderBy = orderBy;
        },
        SET_SEARCH_TRACKS_CURRENT_PAGE: (state, data) => {
            state.searchTracksCurrentPage = data;
        },

        SET_SEARCH_RECORD_LABELS: (state, data) => {
            state.searchRecordLabelsResult = data.items;
            state.searchCountRecordLabels = data.count_results;
        },
        SET_SEARCH_RECORD_LABELS_CURRENT_PAGE: (state, data) => {
            state.searchRecordLabelsCurrentPage = data;
        }
    },
    actions: {
        async INITIALIZE_SEARCH({ state }) {
            if (!cachedDataSource) {
                initializeDataSourceConfig();
            }
            return Promise.resolve();
        },
        CLEAN_SEARCH_RESULT({commit}) {
            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_SEARCH_ARTISTS', empty);
            commit('SET_SEARCH_TRACKS', empty);
            commit('SET_SEARCH_RECORD_LABELS', {
                items: [],
                count_results: ''
            });
            commit('SET_SEARCH_ALBUMS', {items: [], count_results: ''})
        },

        async SEARCH_ARTISTS({commit}, params) {
            const { source, elasticPath } = getDataSourceConfig();
            try {
                const response = await axios.get(`${elasticPath}artists`, {params});
                if (!response.data && source.auto === true) {
                    const apiResponse = await axios.get('artists', {params});
                    commit('SET_SEARCH_ARTISTS', apiResponse.data);
                } else {
                    commit('SET_SEARCH_ARTISTS', response.data);
                }
            } catch (err) {
                console.error(`SEARCH_ARTISTS ${err}`);
            }
        },

        async SEARCH_ALBUMS({commit}, params) {
            const { source, elasticPath } = getDataSourceConfig();
            try {
                const response = await axios.get(`${elasticPath}optimized/music_releases`, {params});
                if (!response.data && source.auto === true) {
                    const apiResponse = await axios.get('optimized/music_releases', {params});
                    commit('SET_SEARCH_ALBUMS', apiResponse.data);
                } else {
                    commit('SET_SEARCH_ALBUMS', response.data);
                }
            } catch (err) {
                console.error(`SEARCH_ALBUMS ${err}`);
            }
        },

        async SEARCH_TRACKS({commit}, params) {
            const { source, elasticPath } = getDataSourceConfig();
            try {
                const response = await axios.get(`${elasticPath}songs`, {params});
                if (!response.data && source.auto === true) {
                    const apiResponse = await axios.get('songs', {params});
                    commit('SET_SEARCH_TRACKS', apiResponse.data);
                } else {
                    commit('SET_SEARCH_TRACKS', response.data);
                }
            } catch (err) {
                console.error(`SEARCH_TRACKS ${err}`);
            }
        },

        async SEARCH_RECORD_LABELS({commit}, params) {
            const { source, elasticPath } = getDataSourceConfig();
            try {
                const response = await axios.get(`${elasticPath}record_labels`, {params});
                if (!response.data && source.auto === true) {
                    const apiResponse = await axios.get('record_labels', {params});
                    commit('SET_SEARCH_RECORD_LABELS', apiResponse.data);
                } else {
                    commit('SET_SEARCH_RECORD_LABELS', response.data);
                }
            } catch (err) {
                console.error(`SEARCH_RECORD_LABELS ${err}`);
            }
        },
        UPDATE_SEARCH_ALBUMS_ORDER_BY: ({commit}, orderBy) => {
            commit('SET_SEARCH_ALBUMS_ORDER_BY', orderBy);
        },
    },
    getters: {
        SEARCH_STRING: state => state.searchString,
        SEARCH_LOADER: state => state.searchLoader,
        SEARCH_DETAILS_CONTENT: state => state.searchDetailsContent,

        // Artists
        SEARCH_ARTISTS_RESULT: state => state.searchArtistsResult,
        SEARCH_COUNT_ARTISTS: state => state.searchCountArtists,
        SEARCH_ARTISTS_CURRENT_PAGE: state => state.searchArtistsCurrentPage,

        // Albums
        SEARCH_ALBUMS_RESULT: state => state.searchAlbumsResult,
        SEARCH_COUNT_ALBUMS: state => state.searchCountAlbums,
        SEARCH_VIEW_ALBUMS: state => state.searchViewAlbums,
        SEARCH_ALBUMS_ORDER_BY: state => state.searchAlbumsOrderBy,
        SEARCH_ALBUMS_CURRENT_PAGE: state => state.searchAlbumsCurrentPage,

        // Tracks
        SEARCH_TRACKS_RESULT: state => state.searchTracksResult,
        SEARCH_COUNT_TRACKS: state => state.searchCountTracks,
        SEARCH_TRACKS_ORDER_BY: state => state.searchTracksOrderBy,
        SEARCH_TRACKS_CURRENT_PAGE: state => state.searchTracksCurrentPage,

        // Record Labels
        SEARCH_RECORD_LABELS_RESULT: state => state.searchRecordLabelsResult,
        SEARCH_COUNT_RECORD_LABELS: state => state.searchCountRecordLabels,
        SEARCH_RECORD_LABELS_CURRENT_PAGE: state => state.searchRecordLabelsCurrentPage,

        IS_SEARCH_INITIALIZED: state => state.isInitialized,
    }
}
