<template>
    <transition name="fade">
        <div v-if="show" class="app-loading-screen">
            <div class="loading-avatar"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LoadingScreen',
    props: {
        show: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>
.app-loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-avatar {
    width: 80vh;
    height: 80vh;
    background-image: url('/img/svg/square_avatar.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    outline: none;
}
</style> 