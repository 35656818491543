export default {
    state: {
        email: 'mailto:admin@psychurch.org',
        facebook: 'https://www.facebook.com/churchofpsy/',
        patreon: 'https://www.patreon.com/psychurch',
        discord: 'https://discord.com/invite/bnheaa9J',

    },
    getters: {
        EMAIL: state => state.email,
        FACEBOOK: state => state.facebook,
        PATREON: state => state.patreon,
        DISCORD: state => state.discord,
    }
}