<template>
	<div>
		<transition name="back-in" :duration="{ enter: 500, leave: 200 }">

			<div class="player bounceInUp" v-if="playerStatus">
				<div class="left_">

					<button type="button" class="prev" @click="skip('prev')"></button>

					<div class="toggle_actions">
						<button type="button" v-ripple class="toggle_play" v-if="!playing" @click="play"></button>
						<button type="button" v-ripple class="toggle_pause" v-if="playing" @click="pause"></button>
						<button type="button" v-ripple class="mobile_menu" :class="{'toggle_arrow': drawerPlayer}" @click="openPlayerMobileMenu">
							<span class="material-icons">keyboard_arrow_up</span>
						</button>
						<v-progress-circular
							v-if="!mobile && (playListLoading || !trackOnload)"
							:size="50"
							:width="2"
							indeterminate
							color="#ffffff">
						</v-progress-circular>
					</div>

					<button id="next-track-btn" type="button" class="next" @click="skip('next')"></button>

					<button class="next_album" v-if="playZone && playZone.length && currentFromPlay !== 'track_default'" @click="nextAlbum"></button>

					<div class="info_track">

						<div class="img" :class="{'hover': albumHover}"
						     v-if="currentAlbum.smallImage || currentAlbum.image" @mouseover="albumHover = true" @mouseleave="albumHover = false">
							<div @click="redirectToAlbum(currentAlbum.id, currentAlbum.identifier)">
								<img :src="currentAlbum.smallImage || currentAlbum.image" :alt="currentAlbum.title">
							</div>
						</div>
						<div class="title_artist">
							<div class="track_title" v-if="currentTrack">
                                <span @mouseover="albumHover = true" @mouseleave="albumHover = false"
                                      @click="redirectToAlbum(currentAlbum.id, currentAlbum.identifier)" :class="{'hover': albumHover}">
                                    {{ currentTrack.title }}
                                </span>
								<div class="bpm" v-if="currentTrack &&currentTrack.bpm !== 0">({{ currentTrack.bpm }} BPM)</div>
								<!--<span class="key" v-if="currentTrack.key || currentTrack.key !== null">-->
							</div>

							<div class="track_artist" v-if="currentTrack && currentTrack.artist">
								<span>By</span>
								<div @click="redirectToArtistPage(currentTrack.artist.id, currentTrack.artist.identifier)" class="artist_name">
									{{ currentTrack.artist.name }}
								</div>
							</div>

							<div v-else class="track_artist">-</div>
						</div>
					</div>
				</div>

				<div class="progress">
					<!-- <span class="time_current">{{ duration / 100 * progress | minutes }}</span> -->

					<!-- <v-progress-linear
						v-model="progress"
						color="#20C4F5"
					></v-progress-linear> -->

					<div class="progress_line">
						<template>

							<v-slider
                ref="slider"
								:disabled="!trackOnload"
								color="#20C4F5"
								track-color="#7481A6"
								track-fill-color="#20C4F5"
								thumb-color="#20C4F5"
								thumb-size="0"
								v-model="progress"
								step="0.1"
								@change="changeSetSeek($event)"
								@mousedown="mouseDownSetSeek($event)"
								@mouseup="mouseUpSetSeek($event)"
								@touchstart.native.prevent="mouseDownSetSeek"
								@touchmove.native.prevent="handleTouchMove"
								@touchend.native.prevent="mouseUpSetSeek"
								min="0"
							>
							</v-slider>
						</template>
					</div>
					<!-- <span class="time_end">{{ duration | minutes }}</span> -->
				</div>

				<div class="right_box">

					<div class="time_details">
						<span>{{ duration / 100 * progress | minutes }}/{{ duration | minutes }}</span>
					</div>

					<!-- SEPARATOR -->
					<div class="separator"></div>

					<!-- SHARE -->
					<button type="button" v-ripple class="share_link" @click="createLinkTrack"></button>

					<!-- FAVORITE -->
					<div class="track_purchased" v-if="currentTrack && currentTrack.isPurchased === true"></div>
					<template v-else>
						<button v-if="currentTrack && currentTrack.isFavorite === false" @click.stop="addTrackToFavorites(currentTrack, currentAlbum)" class="like">
							<span class="material-icons">favorite_border</span>
						</button>
						<button v-if="currentTrack && currentTrack.isFavorite === true"
                    @click.stop="removeTrackFromFavorites(currentTrack, currentAlbum)"
                    class="like active">
							<span class="material-icons">favorite</span>
						</button>
					</template>

					<!-- ADD TRACK TO PLAYLIST  -->
					<button class="playlist-add">
						<span class="material-icons">playlist_add</span>
					</button>

					<!-- MORE -->
					<button class="more" @click="modalMoreIcon = !modalMoreIcon">
						<span class="material-icons">more_vert</span>
					</button>

					<!-- TOGGLE WIDTH -->
					<!-- <button @click="playerToggleWidth = !playerToggleWidth" class="toggle-width">
						<span class="material-icons">keyboard_arrow_down</span>
					</button> -->

					<!-- LOOP -->
					<!--    <button :class="[loop ? 'loop_on' : '', 'loop']" @click="toggleLoop"></button>-->

					<!-- VOLUME -->
					<!--                    <div class="volume-controls-wrapper">-->
					<!--                        <button class="volume"-->
					<!--                                :class="[muted ? 'mute' : 'unmute', '']"-->
					<!--                                @click="toggleMute"-->
					<!--                                @mouseenter="showVolume"-->
					<!--                        ></button>-->
					<!--                        <div-->
					<!--                            @mouseenter="setVolumeBarStatus(true)"-->
					<!--                            @mouseleave="setVolumeBarStatus(false)"-->
					<!--                            v-show="volumeStatus">-->
					<!--                            <vue-slider @change="updateVolume" v-bind="volumeOptions"></vue-slider>-->
					<!--                        </div>-->
					<!--                    </div>-->

					<!--QUEUE OF SONGS -->
					<!--                    <button class="play_list" @click="changePlaylistStatus(!showPlayList)"-->
					<!--                            :class="{'active': showPlayList}"></button>-->

					<!--                    <button :class="[shuffle ? 'shuffle_on' : '', 'shuffle']" @click="toggleShuffle"></button>-->

				</div>

				<v-dialog
					v-if="modalShareLink"
					v-model="modalShareLink"
					transition="dialog-bottom-transition"
					max-width="600">

					<div class="content_popup_share">
						<div class="head_popup">Share</div>
						<div class="input_box">
							<input type="text" id="input-share-track-link" v-model="shareLink">
							<button type="button" v-ripple @click="copyLinkTrack">Copy</button>
						</div>
						<button type="button" v-ripple @click="modalShareLink = false" class="close">Close</button>
					</div>
				</v-dialog>

				<v-card
					v-if="modalMoreIcon"
					v-model="modalMoreIcon"
					class="more_card"
					>
						<div class="wrap_drawer_list">
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === false"
                    @click="addTrackToFavorites(currentTrack, currentAlbum)" v-ripple class="favorite">Favorite
						</button>
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === true"
                    @click="removeTrackFromFavorites(currentTrack, currentAlbum)" v-ripple class="active favorite">Favorite
						</button>
						<button type="button" v-ripple class="share" @click="createLinkTrack">Share</button>
						<button type="button" v-ripple class="add_to_playlist" @click="dialogComingSoonVisible = true; drawerPlayer = false">Add to Playlist
						</button>
					</div>
					
				</v-card>

			</div>
		</transition>

		<PageTransitionIndicator 
			:show="pageTransition.show"
			:direction="pageTransition.direction"
		/>

		<template v-if="mobile">
			<v-navigation-drawer
				v-model="drawerPlayer"
				fixed
				bottom
				color="#192234"
				:style="{ transform: `translateY(${drawerTransform}px)`, transition: isDraggingDrawer ? 'none' : 'transform 0.3s' }"
			>
				<div class="wrap_drawer" 
					 @touchstart="handleDrawerTouchStart"
					 @touchmove="handleDrawerTouchMove"
					 @touchend="handleDrawerTouchEnd">

					<div class="wrap_drawer_player">
						<div class="action_buttons">
							<button type="button" v-ripple class="prev_track" @click="skip('prev')"></button>
							<div class="toggle_play">
								<button type="button" v-ripple class="play" v-if="!playing" @click="play"></button>
								<button type="button" v-ripple class="pause" v-if="playing" @click="pause"></button>
							</div>
							<button type="button" v-ripple class="next_track" @click="skip('next')"></button>
						</div>
						<div class="track_progress">
							<div class="time_displays">
								<span class="time_current">{{ duration / 100 * progress | minutes }}</span>
								<span class="time_end">{{ duration | minutes }}</span>
							</div>
							
							<div class="slider_container">
								<v-slider
									ref="slider"
									:disabled="!trackOnload"
									color="#20C4F5"
									track-color="#7481A6"
									track-fill-color="#20C4F5"
									thumb-color="#20C4F5"
									thumb-size="0"
									v-model="progress"
									step="0.1"
									@change="changeSetSeek($event)"
									@mousedown="mouseDownSetSeek($event)"
									@mouseup="mouseUpSetSeek($event)"
									@touchstart.native.prevent="mouseDownSetSeek"
									@touchmove.native.prevent="handleTouchMove"
									@touchend.native.prevent="mouseUpSetSeek"
									min="0"
								>
								</v-slider>
							</div>
						</div>
					</div>
					<div class="wrap_drawer_list">
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === false"
                    @click="addTrackToFavorites(currentTrack, currentAlbum)" v-ripple class="favorite">Favorite
						</button>
						<button type="button" v-if="currentTrack && currentTrack.isFavorite === true"
                    @click="removeTrackFromFavorites(currentTrack, currentAlbum)" v-ripple class="active favorite">Favorite
						</button>
						<button type="button" v-ripple class="share" @click="createLinkTrack">Share</button>
						<button type="button" v-ripple class="add_to_playlist" @click="dialogComingSoonVisible = true; drawerPlayer = false">Add to Playlist
						</button>
					</div>
					<!-- <button type="button" v-ripple class="close_drawer" @click="closePlayerMobileMenu">Cancel</button> -->

				</div>
			</v-navigation-drawer>
		</template>


		<dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible"></dialogNeedLogin>
		<comingSoonDialog v-if="dialogComingSoonVisible" v-model="dialogComingSoonVisible"></comingSoonDialog>
		<v-dialog v-if="dialogErrorTrack" v-model="dialogErrorTrack" max-width="600">
			<div class="need_login_dialog style_dialog">
				<h2 class="header">
					Something’s wrong
				</h2>
				<div class="cnt">
					There seems to be a problem connecting to our server<br>
					You can try again or come back at a later time
				</div>
				<div class="wrap_actions">
					<button type="button" class="cancel" @click="dialogErrorTrack = false" v-ripple>Cancel</button>
					<button type="button" class="retry" @click="reloadTrack" v-ripple>Retry</button>
				</div>
			</div>
		</v-dialog>
	</div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import dialogNeedLogin from '@/components/dialogs/needLogin';
import comingSoonDialog from '@/components/dialogs/comingSoonDialog';
import PageTransitionIndicator from './pageTransitionIndicator.vue';

// mixins
import isMobile from "@/mixins/isMobile";
import addTrackToFavorites from "@/mixins/musicLike/addTrackToFavorites";
import removeTrackFromFavorites from "@/mixins/musicLike/removeTrackFromFavorites";

export default {
	name: "playerControlBars",
	components: {
		dialogNeedLogin,
		comingSoonDialog,
		PageTransitionIndicator
	},
	mixins: [
		isMobile,
		addTrackToFavorites,
		removeTrackFromFavorites,
	],
	data() {
		return {
			progress: 0,
			duration: 0,
      currentTime: 0,
			like: false,
			shareLink: '',
			modalShareLink: false,
			volumeStatus: false,
			volumeBar: false,
			volume: 0.5,
			volumeOptions: {
				direction: 'btt',
				tooltip: 'none',
				min: 0.1,
				max: 1,
				interval: 0.1
			},
			needLoginDialog: {
				action: null,
				visible: false,
			},
			dialogComingSoonVisible: false,
			dialogErrorTrack: false,
			hasScrolledToBottom: false,
			drawerPlayer: false,
			playerToggleWidth: false,
			changeProgress: false,
			albumHover: false,
			draggingSlider: false,
			modalMoreIcon: false,
			drawerTransform: 0,
			isDraggingDrawer: false,
			startDragY: 0,
			playerHeight: 64,
			lastWindowHeight: window.visualViewport ? window.visualViewport.height : window.innerHeight,
			pageTransition: {
				show: false,
				direction: 'next'
			}
		}
	},
	watch: {
		currentAlbum: {
			handler: function () {
        this.initMediaSession();
				if (this.currentAlbum && this.currentAlbum.playList) {
					this.currentAlbum.playList[0].firstTrack = true;
					this.currentAlbum.playList[this.currentAlbum.playList.length - 1].lastTrack = true;
				}
			},
			deep: true
		},
		trackList: {
			handler: function () {
        this.initMediaSession();
				if (this.trackList.length) {
					this.trackList[0].firstTrack = true;
					this.trackList[this.trackList.length - 1].lastTrack = true;
				}
			}
		},
    currentTrack: {
      handler: function () {
        this.initMediaSession();
      },
      deep: true
    },
		trackOnload(value) {
			if (value) {
				requestAnimationFrame(this.animateProgress);
			}
		},
		soundError() {
			this.dialogErrorTrack = true;
		},
		playerStatus() {
			if (!window.onkeydown) {
				window.addEventListener('keydown', this.handleKeydown);
			}
      this.initMediaSession();
		},
		playing() {
      if (!window.onkeydown) {
				window.addEventListener('keydown', this.handleKeydown);
			}
      this.initMediaSession();
		},
		'ALBUMS_CURRENT_PAGE'(newPage, oldPage) {
			if (newPage !== oldPage) {
				this.pageTransition.direction = newPage > oldPage ? 'next' : 'prev';
				this.pageTransition.show = true;
				setTimeout(() => {
					this.pageTransition.show = false;
				}, 2000);
			}
		}
	},
	computed: {
		...mapGetters({
			authData: 'AUTH_DATA',
			playing: 'PLAYING',
			playerStatus: 'PLAYER_STATUS',
			currentTrack: 'CURRENT_TRACK',
			currentAlbum: 'CURRENT_ALBUM',
			trackList: 'TRACKS',
			sound: 'SOUND',
			shuffle: 'SHUFFLE',
			currentTrackIdx: 'CURRENT_TRACK_IDX',
			loop: 'LOOP',
			muted: 'MUTED',
			// duration: 'DURATION',
			showPlayList: 'SHOW_PLAY_LIST',
			discovery: 'DISCOVERY',
			currentFromPlay: 'CURRENT_FROM_PLAY',
			playZone: 'PLAYED_ZONE',
			trackOnload: 'TRACK_ONLOAD',
			playListLoading: 'FULL_ALBUM_LOADING',
			fullAlbum: 'FULL_ALBUM',
			currentPage: 'CURRENT_PAGE',
			soundError: 'SOUND_ERROR',
			changeSeek: 'SEEK',
			bufferProgress: 'BUFFER_PROGRESS',
		}),
	},
	methods: {
		...mapActions({
			play: 'SET_PLAY',
			pause: 'SET_PAUSE',
			stop: 'STOP_PLAY',
			toggleShuffle: 'TOGGLE_SHUFFLE',
			toggleLoop: 'TOGGLE_LOOP',
			// nextAlbum: 'SET_NEXT_ALBUM',
			toggleMute: 'TOGGLE_MUTE',
			setSoundVolume: 'SET_SOUND_VOLUME',
			changePlaylistStatus: 'CHANGE_PLAY_LIST_STATUS',
			skipAlbum: 'SKIP_ALBUM'
		}),

		mouseDownSetSeek(event) {
			// Early return if slider is disabled
			if (!this.trackOnload) return;

			this.draggingSlider = true;
			this.changeProgress = true;
			
			if (this.mobile) {
				event.preventDefault();
			}
		},

		handleTouchMove(event) {
			// Early return if not dragging or slider is disabled
			if (!this.draggingSlider || !this.trackOnload) return;

			if (this.mobile && event.touches && event.touches[0]) {
				const target = this.$refs.slider?.$el;
				if (!target) return;

				const rect = target.getBoundingClientRect();
				const clientX = event.touches[0].clientX;
				
				// Constrain touch position to slider bounds
				const boundedX = Math.max(rect.left, Math.min(rect.right, clientX));
				const offsetX = boundedX - rect.left;
				const percentage = (offsetX / target.offsetWidth) * 100;
				
				this.progress = Math.max(0, Math.min(100, percentage));
			}
			
			this.draggingSlider = true;
			this.changeProgress = true;
			
			event.preventDefault();
			event.stopPropagation();
		},

		mouseUpSetSeek(event) {
			// Early return if slider is disabled
			if (!this.trackOnload) return;

			if (this.mobile) {
				const target = this.$refs.slider?.$el;
				if (!target) return;

				const rect = target.getBoundingClientRect();
				const clientX = event.changedTouches?.[0]?.clientX;
				if (!clientX) return;

				// Constrain touch position to slider bounds
				const boundedX = Math.max(rect.left, Math.min(rect.right, clientX));
				const offsetX = boundedX - rect.left;
				const percentage = (offsetX / target.offsetWidth) * 100;
				
				this.progress = Math.max(0, Math.min(100, percentage));
				
				// Only update audio time if the change is significant
				if (Math.abs(this.sound.currentTime - (this.progress / 100) * this.duration) > 0.1) {
					this.sound.currentTime = (this.progress / 100) * this.duration;
				}
			}

			// Reset flags after a short delay to prevent jumps
			setTimeout(() => {
				this.draggingSlider = false;
				this.changeProgress = false;
			}, 50);
		},

		animateProgress() {
			if (this.playing) {
				// Remove any existing listener to prevent duplicates
				this.sound.removeEventListener('timeupdate', this.handleTimeUpdate);
				this.sound.addEventListener('timeupdate', this.handleTimeUpdate);
			}
		},

		handleTimeUpdate() {
			if (!this.draggingSlider && !this.changeProgress) {
				const currentTime = this.sound.currentTime;
				this.duration = this.sound.duration;
				
				// Only update if the change is significant
				const newProgress = (currentTime / this.duration) * 100;
				if (Math.abs(this.progress - newProgress) > 0.1) {
					this.progress = newProgress;
				}
			}
		},

		updateSeek(direction) {
			const seekChange = (direction === 'prev') ? -10 : 10;
			this.sound.currentTime = this.sound.currentTime + seekChange;
		},

		handleKeydown(e) {
      switch (e.keyCode) {
				case 37: // left arrow
					this.updateSeek('prev');
					break;
				case 39: // right arrow
					this.updateSeek('next');
					break;
			}
		},

		changeSetSeek(percents) {
      this.sound.currentTime = (Math.floor(percents) / 100) * this.duration;
		},

    /**
     * Initializes the Media Session API for controlling multimedia playback.
     * Sets the metadata of the current track (title, artist, album, and album artwork) and
     * action handlers such as play, pause, previoustrack, and nexttrack to provide control
     * over multimedia playback in the web application via system playback controls.
     */
    initMediaSession() {
      if ('mediaSession' in navigator) {
        const title = this.currentTrack ? this.currentTrack.title || 'No Track Title' : 'No Track Tile';
        const artist = this.currentTrack && this.currentTrack.artist ? this.currentTrack.artist.name || 'No Artist' : 'No Artist';
        const album = this.currentAlbum ? this.currentAlbum.title || 'No Album Title' : 'No Album Title';
        const artworkSrc = this.currentAlbum ? this.currentAlbum.smallImage || '' : '';

        navigator.mediaSession.metadata = new MediaMetadata({
          title: title,
          artist: artist,
          album: album,
          artwork: [
            { src: artworkSrc, sizes: '96x96',   type: 'image/png' },
            { src: artworkSrc, sizes: '128x128', type: 'image/png' },
            { src: artworkSrc, sizes: '192x192', type: 'image/png' },
            { src: artworkSrc, sizes: '256x256', type: 'image/png' },
            { src: artworkSrc, sizes: '384x384', type: 'image/png' },
            { src: artworkSrc, sizes: '512x512', type: 'image/png' },
          ]
        });

        navigator.mediaSession.setActionHandler('play', () => {
          this.$store.commit('SET_CURRENT_PLAYING', true);
          this.sound.play();
        });

        navigator.mediaSession.setActionHandler('pause', () => {
          this.$store.commit('SET_CURRENT_PLAYING', false);
          this.sound.pause();
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
          this.skip('prev');
        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
          this.skip('next');
        });

		navigator.mediaSession.setActionHandler('seekto', (details) => {
         if (details.seekTime) {
            this.sound.currentTime = details.seekTime;
           }
        });
      }
    },

		nextAlbum() {
			this.skipAlbum('next');
		},
		swipe(direction) {
			if (direction === 'Down') {
				this.drawerPlayer = false;
			}
		},
		redirectToAlbum(albumID, albumTitle) {
			this.$router.push({name: 'albumDetails', params: {name: window.toHumanFormat(albumTitle), id: albumID}});
		},
		redirectToArtistPage(artistId, artistName) {
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		skip(direction) {
      this.$store.dispatch('SKIP_TRACK', direction);
		},
		reloadTrack() {
			this.play();
		},
		async createLinkTrack() {
			this.closePlayerMobileMenu();
			let track = this.currentAlbum.playList.filter((item) => item.song.id === this.currentTrack.id);
			
			// Use psychurch.org domain for development, otherwise use current hostname
			const hostname = window.location.hostname === 'localhost' ? 'psychurch.org' : window.location.hostname;
			const shareUrl = `https://${hostname}/album/${this.currentAlbum.id}/${track[0].id}`;
			
			// Check if Web Share API is available
			if (navigator.share) {
				try {
					await navigator.share({
						title: this.currentAlbum.title,
						url: shareUrl
					});
				} catch (err) {
					if (err.name !== 'AbortError') {
						// Show fallback dialog if share failed for reason other than user cancellation
						this.modalShareLink = true;
						this.shareLink = shareUrl;
					}
				}
			} else {
				// Fallback to copy link dialog
				this.modalShareLink = true;
				this.shareLink = shareUrl;
			}
		},
		copyLinkTrack() {
			navigator.clipboard.writeText(this.shareLink);
		},
		onScroll: function () {
			window.onscroll = () => {
				this.hasScrolledToBottom = document.documentElement.scrollTop + window.innerHeight + 42 >= document.documentElement.scrollHeight;
			};
		},
		openPlayerMobileMenu() {
			if (this.drawerPlayer) {
				// Close drawer
				this.drawerPlayer = false;
			} else {
				// Open drawer - update position before opening
				this.updateDrawerPosition();
				
				// Small delay to ensure position is updated before animation
				setTimeout(() => {
					this.drawerPlayer = true;
					this.drawerTransform = 0;
				}, 50);
			}
		},
		closePlayerMobileMenu() {
			this.drawerPlayer = false;
		},
		handleDrawerTouchStart(event) {
			// Don't initiate drag on interactive elements
			if (event.target.closest('button') || event.target.closest('.v-slider')) {
				return;
			}
			
			this.updateDrawerPosition();
			this.isDraggingDrawer = true;
			this.startDragY = event.touches[0].clientY;
			this.drawerTransform = 0;
			
			// Prevent default only on the drawer background
			if (event.target.closest('.v-navigation-drawer') && !event.target.closest('button')) {
				event.preventDefault();
			}
		},
		handleDrawerTouchMove(event) {
			if (!this.isDraggingDrawer) return;
			
			// Prevent default only on the drawer background
			if (event.target.closest('.v-navigation-drawer') && !event.target.closest('button')) {
				event.preventDefault();
			}
			
			const currentY = event.touches[0].clientY;
			const deltaY = currentY - this.startDragY;
			
			// Only allow downward movement
			if (deltaY > 0) {
				this.drawerTransform = deltaY;
			}
		},
		handleDrawerTouchEnd(event) {
			if (!this.isDraggingDrawer) return;

			const threshold = 100;
			
			if (this.drawerTransform > threshold) {
				this.drawerPlayer = false;
			} else {
				this.drawerTransform = 0;
			}
			
			this.isDraggingDrawer = false;
		},
		updateDrawerPosition() {
			// Use visual viewport height instead of window.innerHeight
			const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
			const vh = viewportHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			const playerElement = document.querySelector('.player');
			if (playerElement) {
				const playerRect = playerElement.getBoundingClientRect();
				this.playerHeight = playerRect.height;
				
				// Calculate bottom position relative to visual viewport
				const bottomPosition = viewportHeight - playerRect.top;
				document.documentElement.style.setProperty('--player-height', `${this.playerHeight}px`);
				document.documentElement.style.setProperty('--bottom-position', `${bottomPosition}px`);
			}
		},
		handleResize() {
			const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
			if (Math.abs(viewportHeight - this.lastWindowHeight) > 50) {
				this.lastWindowHeight = viewportHeight;
				this.updateDrawerPosition();
			}
		},
		handleScroll() {
			// Debounce scroll updates
			if (this.scrollTimeout) {
				clearTimeout(this.scrollTimeout);
			}
			this.scrollTimeout = setTimeout(() => {
				this.updateDrawerPosition();
			}, 100);
		},
	},
	mounted() {
		this.updateDrawerPosition();
		
		// Use visualViewport events when available
		if (window.visualViewport) {
			window.visualViewport.addEventListener('resize', this.handleResize);
			window.visualViewport.addEventListener('scroll', this.handleScroll);
		} else {
			window.addEventListener('resize', this.handleResize);
			window.addEventListener('scroll', this.handleScroll);
		}
		
		window.addEventListener('orientationchange', () => {
			setTimeout(this.updateDrawerPosition, 100);
		});
	},
	beforeDestroy() {
		if (window.visualViewport) {
			window.visualViewport.removeEventListener('resize', this.handleResize);
			window.visualViewport.removeEventListener('scroll', this.handleScroll);
		} else {
			window.removeEventListener('resize', this.handleResize);
			window.removeEventListener('scroll', this.handleScroll);
		}
		window.removeEventListener('orientationchange', this.updateDrawerPosition);
		if (this.scrollTimeout) {
			clearTimeout(this.scrollTimeout);
		}
		// Clean up timeupdate listener
		if (this.sound) {
			this.sound.removeEventListener('timeupdate', this.handleTimeUpdate);
		}
	}
}
</script>

<style scoped lang="scss">

</style>
